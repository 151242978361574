/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, ElementType, PropsWithChildren, forwardRef } from 'react';

/**
 * Export `RawHtmlProps` type.
 */

export type RawHtmlProps = PropsWithChildren<
  ComponentPropsWithoutRef<'span'> & {
    element?: keyof JSX.IntrinsicElements | ElementType;
    elementProps?: Record<string, unknown>;
  }
>;

/**
 * Export `RawHtml` component.
 */

export const RawHtml = forwardRef<HTMLElement, RawHtmlProps>((props, ref) => {
  const { children, element, elementProps, ...rest } = props;
  const Element = element ?? 'span';

  return (
    <Element
      // eslint-disable-next-line id-match
      dangerouslySetInnerHTML={{ __html: children }}
      {...rest}
      {...elementProps}
      ref={ref}
    />
  );
});

/**
 * Set display name.
 */

RawHtml.displayName = 'RawHtml';
