/**
 * Module dependencies.
 */

import { Breakpoint, breakpoints } from 'src/styles/breakpoints';
import { useMediaQuery } from './use-media-query';
import { useMemo } from 'react';

/**
 * `Value` type.
 */

type Value = Breakpoint | number;

/**
 * Export `useBreakpoint` hook.
 */

export function useBreakpoint(min: Value, max?: Value, axis: 'width' | 'height' = 'width') {
  const query = useMemo(() => {
    const minValue = typeof min === 'number' ? min : breakpoints[min as Breakpoint];
    const maxValue = typeof max === 'number' ? max : breakpoints[max as Breakpoint];
    const minQuery = `(min-${axis}: ${minValue}px)`;
    const maxQuery = maxValue ? ` and (max-${axis}: ${maxValue}px)` : '';

    return `${minQuery}${maxQuery}`;
  }, [axis, max, min]);

  return useMediaQuery(query);
}
