/**
 * Module dependencies.
 */

import ReactQRCode from 'react-qr-code';
import styled from 'styled-components';

/**
 * Export `QRCode` styled component.
 */

export const QRCode = styled(ReactQRCode).attrs({
  bgColor: 'transparent',
  fgColor: 'currentColor',
  size: 512,
  viewBox: `0 0 512 512`
})`
  height: auto;
  max-width: 100%;
  width: 100%;
`;
