/**
 * Module dependencies.
 */

import 'react-toastify/dist/ReactToastify.css';
import { ReactNode } from 'react';
import { Slide, ToastContainer, ToastOptions, toast as reactToast } from 'react-toastify';
import { Svg } from 'src/components/core/svg';
import { createGlobalStyle } from 'styled-components';
import { media } from 'src/styles/media';
import { textStyles } from 'src/styles/typography';
import { transparentize } from 'src/styles/utils/colors';
import errorSvg from 'src/assets/svgs/32/alert-circle.svg';
import successSvg from 'src/assets/svgs/32/check-circle.svg';

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode;
};

/**
 * `Options` type.
 */

type Options = ToastOptions | undefined;

/**
 * `Message` type.
 */

type Message = string | ReactNode;

/**
 * Toast global styles.
 */

const ToastGlobalStyles = createGlobalStyle`
  .Toastify {
    --toastify-color-dark: var(--color-white);
    --toastify-background-color: ${transparentize('neutral100', 0.72)};
    --toastify-color-light: var(--color-white);
    --toastify-color-success: var(--color-white);
    --toastify-font-family: var(--font-family-default);
    --toastify-text-color-light: var(--color-neutral0);
    --toastify-toast-width: clamp(240px, 40vw, 420px);
    --toastify-z-index: var(--z-index-toast);
  }

  .Toastify__toast-container .Toastify__toast {
    ${textStyles.paragraph2}

    backdrop-filter: blur(8px);
    background-color: var(--toastify-background-color);
    border-radius: var(--border-radius);
    margin-top: 16px;
    padding: 0;

    ${media.max.xs`
      margin: 16px;
    `}

    &-body {
      padding: 0 16px;
      white-space: pre-line;
      word-break: break-word;
    }

    &-icon {
      margin-inline-end: 16px;
      width: 32px;

      & > span {
        width: 32px;
      }
    }

    &--error {
      background: linear-gradient(270deg, rgba(26, 34, 46, 0) 0%, rgba(254, 88, 54, 0.1) 79.69%, rgba(254, 88, 54, 0.15) 100%), var(--toastify-background-color);

      svg {
        color: var(--color-critical40);
      }
    }

    &--success {
      background: linear-gradient(270deg, rgba(26, 34, 46, 0) 0%, rgba(82, 206, 80, 0.15) 69.51%, rgba(132, 251, 127, 0.2) 100%), var(--toastify-background-color);

      svg {
        color: var(--color-primaryForDark30);
      }
    }
  }
`;

/**
 * Alert Icons.
 */

const alertIcons = {
  error: errorSvg,
  success: successSvg
} as const;

/**
 * Default options.
 */

const defaultOptions = {
  icon: ({ type }: { type: keyof typeof alertIcons }): any => <Svg icon={alertIcons?.[type]} size={'24px'} />
};

/**
 * Export `toast` util.
 */

export const toast = {
  error: (message: Message, options?: Options) => {
    reactToast.error(message, { ...defaultOptions, ...options } as any);
  },
  success: (message: Message, options?: Options) => {
    reactToast.success(message, { ...defaultOptions, ...options } as any);
  }
} as const;

/**
 * Export `ToastProvider` component.
 */

export function ToastProvider({ children }: Props) {
  return (
    <>
      {children}

      <ToastGlobalStyles />

      <ToastContainer
        autoClose={5000}
        closeButton={false}
        closeOnClick
        hideProgressBar
        limit={3}
        newestOnTop
        pauseOnHover
        position={'bottom-left'}
        stacked
        transition={Slide}
      />
    </>
  );
}
