/**
 * Module dependencies.
 */

import { RawHtml, RawHtmlProps } from 'src/components/core/raw-html';
import { forwardRef, useRef } from 'react';
import { isExternalUrl } from 'src/core/utils/url';
import { mergeRefs } from 'src/core/utils/refs';
import { useInternalUrl } from 'src/hooks/use-internal-url';
import { useRouter } from 'next/router';

/**
 * Export `RichTextClean` component.
 */

export const RichTextClean = forwardRef<HTMLSpanElement, RawHtmlProps>((props, ref) => {
  const refElem = useRef<HTMLSpanElement>(null!);
  const router = useRouter();
  const isInternalUrl = useInternalUrl();

  return (
    <RawHtml
      {...props}
      onClick={(event: any) => {
        try {
          const anchor = (event.target as HTMLElement).closest('a');

          if (
            anchor &&
            refElem.current.contains(anchor) &&
            anchor.target !== '_blank' &&
            isInternalUrl(new URL(anchor.href).pathname) &&
            (anchor.href.includes(window.location.host) || !isExternalUrl(anchor.href))
          ) {
            router.push(new URL(anchor.href).pathname);
            event.preventDefault();
          }
        } catch {
          // Allow the default behavior to happen
        }

        props.onClick?.(event);
      }}
      ref={mergeRefs(ref, refElem)}
    />
  );
});

/**
 * Display name.
 */

RichTextClean.displayName = 'RichTextClean';
