/**
 * Module dependencies.
 */

import { Combobox } from '@headlessui/react';
import { Dropdown, DropdownProps } from 'src/components/core/dropdown';
import {
  DropdownIndicator,
  LoadingIndicator,
  NoResultsWrapper,
  SelectButton,
  SelectOption,
  SelectOptions,
  SelectWrapper
} from './styles';

import { FormGroup, FormGroupProps } from 'src/components/core/forms/form-group';
import { ForwardedRef, Fragment, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'src/components/core/forms/input';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { Virtuoso } from 'react-virtuoso';
import { mergeRefs } from 'src/core/utils/refs';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import checkIcon from 'src/assets/svgs/16/check.svg';
import chevronIcon from 'src/assets/svgs/24/chevron-down.svg';
import closeIcon from 'src/assets/svgs/16/close.svg';
import searchIcon from 'src/assets/svgs/20/search.svg';
import spinnerIcon from 'src/assets/svgs/24/spinner.svg';

/**
 * Export `Option` type.
 */

export type Option<T = Record<string, any>> = {
  disabled?: boolean;
  href?: string;
  label: string;
  props?: T;
  value: string | number;
};

/**
 * Export `SelectProps` type.
 */

export type SelectProps = Omit<FormGroupProps, 'defaultValue' | 'value'> & {
  components?: {
    Option?: React.ElementType<Option<any>>;
    Value?: React.ElementType<Option<any>>;
  };
  dropdownPosition?: DropdownProps['position'];
  filterPlaceholder?: string;
  icon?: string;
  isFilterable?: boolean;
  isLoading?: boolean;
  noResultsDescription?: string;
  noResultsLabel?: string;
  onChange?: (value: Option) => void;
  options: Option[];
  placeholder?: string;
  value?: Option | null;
  virtualized?: boolean;
};

/**
 * `ComboboxContent` component.
 */

const ComboboxContent = forwardRef((props: SelectProps & { isOpen: boolean }, ref) => {
  const {
    components,
    dropdownPosition = 'full',
    filterPlaceholder,
    icon,
    id,
    isFilterable,
    isLoading,
    isOpen,
    noResultsDescription,
    noResultsLabel,
    options,
    placeholder,
    value,
    virtualized
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null!);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const isDesktop = useBreakpoint('md');
  const [filter, setFilter] = useState('');
  const filtered = useMemo(
    () =>
      options.filter(
        ({ label, value }) =>
          !filter ||
          String(value).toLowerCase().includes(filter.toLowerCase()) ||
          label.toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, options]
  );

  const renderOption = useCallback(
    (option: Option) => {
      const content = (
        <SelectOption key={option.value} value={option}>
          {({ selected }: { selected?: boolean }) => (
            <>
              {components?.Option ? (
                <components.Option {...option} />
              ) : (
                <>
                  {option.label}

                  {selected && <Svg color={'var(--color-primary)'} icon={checkIcon} size={'16px'} />}
                </>
              )}
            </>
          )}
        </SelectOption>
      );

      if (option.href) {
        return <RouterLink href={option.href}>{content}</RouterLink>;
      }

      return content;
    },
    [components]
  );

  useEffect(() => {
    if (!isOpen) {
      timeout.current = setTimeout(() => setFilter(''), 250);
    }

    if (isOpen && timeout.current) {
      clearTimeout(timeout.current);
    }

    if (isOpen && isDesktop) {
      buttonRef.current.querySelector('input')?.focus();
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [isDesktop, isOpen]);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.setAttribute('tabIndex', 'auto');
    }
  }, [buttonRef]);

  return (
    <SelectButton
      data-placeholder={!value && !!placeholder}
      ref={mergeRefs(ref as ForwardedRef<HTMLButtonElement>, buttonRef)}
    >
      {icon && <Svg icon={icon} size={'20px'} />}

      {value && components?.Value ? <components.Value {...value} /> : (value?.label ?? placeholder)}

      {isLoading ? (
        <LoadingIndicator icon={spinnerIcon} size={'24px'} />
      ) : (
        <DropdownIndicator data-open={isOpen} icon={chevronIcon} size={'24px'} />
      )}

      <Dropdown isOpen={isOpen && !isLoading} position={dropdownPosition} style={{ borderRadius: 8 }} top={8}>
        {isFilterable && (
          <div style={{ padding: 8 }}>
            <Input
              iconLeft={searchIcon}
              id={`${id}-search`}
              onChange={({ target }) => setFilter(target.value)}
              onClick={(event: React.MouseEvent) => event.stopPropagation()}
              placeholder={filterPlaceholder}
              size={'medium'}
              value={filter}
              variant={'filled'}
              {...(!!filter?.length && {
                iconRight: closeIcon,
                onClickRight: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                  event.preventDefault();
                  setFilter('');
                }
              })}
            />
          </div>
        )}

        <SelectOptions static>
          {virtualized && filtered.length > 16 ? (
            <Virtuoso
              itemContent={index => renderOption(filtered[index])}
              style={{ height: '280px' }}
              totalCount={filtered.length}
            />
          ) : (
            filtered.map(option => <Fragment key={option.value}>{renderOption(option)}</Fragment>)
          )}
        </SelectOptions>

        {filtered.length === 0 && (
          <NoResultsWrapper>
            <Text fontWeight={700} variant={'paragraph2'}>
              {noResultsLabel}
            </Text>

            <Text color={'neutral40'} variant={'small'}>
              {noResultsDescription}
            </Text>
          </NoResultsWrapper>
        )}
      </Dropdown>
    </SelectButton>
  );
});

/**
 * `ComboboxContent` display name.
 */

ComboboxContent.displayName = 'ComboboxContent';

/**
 * Export `Select` component.
 */

export const Select = forwardRef<HTMLElement, SelectProps>((props, ref) => {
  const {
    className,
    disabled,
    error,
    helpText,
    id,
    label,
    onChange,
    size = 'default',
    style,
    value,
    variant = 'default'
  } = props;

  return (
    <FormGroup
      className={className}
      data-select-size={size}
      data-select-variant={variant}
      disabled={disabled}
      error={error}
      helpText={helpText}
      id={id}
      label={label}
      value={value?.value}
    >
      <SelectWrapper style={style}>
        <Combobox onChange={onChange} value={value}>
          {({ open }) => <ComboboxContent isOpen={open} ref={ref} {...props} />}
        </Combobox>
      </SelectWrapper>
    </FormGroup>
  );
});

/**
 * `Select` display name.
 */

Select.displayName = 'Select';
