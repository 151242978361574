/**
 * Module dependencies.
 */

import { Snowplow } from './snowplow'
import { adRoll } from './ad-roll'
import { googleAnalytics } from './google-analytics'
import { googleRecaptcha } from './google-recaptcha'
import { impact } from './impact'
import { oneTrust } from './one-trust'
import { tikTokPixel } from './tiktok'
import { tradeDesk } from './trade-desk'
import { useEffect } from 'react'

/**
 * Export `ApplicationScripts`.
 */

export function ApplicationScripts() {
  useEffect(() => {
    oneTrust();
    googleRecaptcha();
    googleAnalytics();
    impact();
    adRoll();
    tradeDesk();
    tikTokPixel();
  }, []);

  return <Snowplow />;
}