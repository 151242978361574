/**
 * Module dependencies.
 */

import { HTMLProps } from 'react';
import { RawHtml } from 'src/components/core/raw-html';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { setLinkProps } from 'src/core/utils/links';
import { transparentize } from 'src/styles/utils/colors';
import arrowRightIcon from 'src/assets/svgs/24/arrow-right.svg';
import styled from 'styled-components';

/**
 * Export `LinkBlockProps` type.
 */

export type LinkBlockProps = {
  href: string;
  subtitle: string;
  title: string;
};

/**
 * `BlockAnchor` styled component.
 */

const BlockAnchor = styled.a.attrs<HTMLProps<HTMLAnchorElement>>(setLinkProps)`
  align-items: space-between;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--modal-grid-padding);
  transition: background-color var(--transition-default);

  & + & {
    border-top: 1px solid ${transparentize('neutral05', 0.08)};
  }

  :focus,
  :hover {
    background-color: ${transparentize('neutral05', 0.05)};
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({
  block: true,
  variant: 'paragraph1'
})`
  margin-bottom: 8px;

  b,
  strong {
    color: var(--color-primaryForDark20);
  }
`;

/**
 * `Subtitle` styled component.
 */

const Subtitle = styled(Text).attrs({
  block: true,
  variant: 'paragraph2'
})`
  color: ${transparentize('white', 0.72)};
`;

/**
 * Export `LinkBlock` component.
 */

export function LinkBlock({ href, subtitle, title }: LinkBlockProps) {
  return (
    <BlockAnchor href={href}>
      <div>
        <RawHtml element={Title}>{title}</RawHtml>

        <RawHtml element={Subtitle}>{subtitle}</RawHtml>
      </div>

      <Svg icon={arrowRightIcon} size={'24px'} />
    </BlockAnchor>
  );
}
