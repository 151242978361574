/**
 * Module dependencies.
 */

import { useState } from 'react';

/**
 * `Key` type.
 */

type Key = string | null;

/**
 * `SessionStorageReturn` type.
 */

type SessionStorageReturn = [unknown, (value: unknown) => void];

/**
 * Export `useSessionStorage` hook.
 */

export const useSessionStorage = (key?: Key, initialValue?: unknown): SessionStorageReturn => {
  const [storedValue, setStoredValue] = useState(() => {
    if (!key) {
      return initialValue;
    }

    try {
      const value = window.sessionStorage.getItem(key);

      if (value !== undefined && value !== null) {
        return JSON.parse(value);
      }

      throw new Error('Not value');
    } catch {
      if (initialValue) {
        window.sessionStorage.setItem(key, JSON.stringify(initialValue));

        return initialValue;
      }
    }
  });

  if (!key) {
    return [
      initialValue,
      () => {
        throw new Error('useSessionStorage key cannot be null');
      }
    ];
  }

  const setValue = (valueToStore: any) => {
    window.sessionStorage.setItem(key, JSON.stringify(valueToStore));

    setStoredValue(valueToStore);
  };

  return [storedValue, setValue];
};
