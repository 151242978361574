/**
 * Module dependencies.
 */

import { ReactNode, useRef } from 'react';
import { breakpoints } from 'src/styles/breakpoints';
import { renderQuery } from 'src/styles/media';
import { transparentize } from 'src/styles/utils/colors';
import CSSTransition from 'react-transition-group/CSSTransition';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  isOpen: boolean;
};

/**
 * `Button` styled component.
 */

/**
 * `Content` styled component.
 */

const Content = styled.div`
  --navbar-bottom-position: calc(100% - var(--navbar-height) - var(--gutter-navbar-y));
  --navbar-mobile-menu-clip-path: inset(
    var(--gutter-navbar-y) var(--gutter-navbar-x) var(--navbar-bottom-position) var(--gutter-navbar-x) round 16px
  );

  align-items: center;
  background-color: ${transparentize('neutral95', 0.85)};
  clip-path: var(--navbar-mobile-menu-clip-path);
  display: flex;
  flex-direction: column;
  height: unset;
  inset: 0;
  justify-content: space-between;
  overflow-x: auto;
  padding: calc(var(--navbar-height) + 48px) 32px 32px;
  position: fixed;
  transition: var(--navbar-transition-default);
  transition-property: background-color, clip-path;

  & ul {
    opacity: 0;
    transform: translateX(-2%);
    transition: var(--navbar-transition-default);
    transition-delay: 0.2s;
    transition-property: opacity, transform;
  }

  &.menu-enter {
    clip-path: var(--navbar-mobile-menu-clip-path);

    & ul {
      opacity: 0;
      transform: translateX(-2%);
    }

    &-active,
    &-done {
      background-color: var(--color-neutral105);
      clip-path: inset(0% 0% 0% 0% round 0);

      & ul {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  }

  &.menu-exit {
    clip-path: inset(0% 0% 0% 0% round 0);

    & ul {
      opacity: 1;
      transform: translateX(0%);
    }

    &-active,
    &-done {
      clip-path: var(--navbar-mobile-menu-clip-path);

      & ul {
        opacity: 0;
        transform: translateX(-5%);
      }
    }
  }

  ${renderQuery('min-width', breakpoints.lg + 80)`
    --navbar-menu-gutter-size: calc((100vw - var(--navbar-max-width)) / 2);
    --navbar-mobile-menu-clip-path: inset(
      var(--gutter-navbar-y) var(--navbar-menu-gutter-size) var(--navbar-bottom-position) var(--navbar-menu-gutter-size) round 16px
    );

    left: calc(var(--navbar-menu-gutter-size) * -1);
    width: 100vw;
  `}
`;

/**
 * Export `DropdownMenu` component.
 */

export function DropdownMenu({ isOpen, ...rest }: Props) {
  const nodeRef = useRef<HTMLDivElement | undefined>();

  return (
    <CSSTransition classNames={'menu'} in={isOpen} mountOnEnter nodeRef={nodeRef} timeout={300} unmountOnExit>
      <Content ref={nodeRef as any} {...rest} />
    </CSSTransition>
  );
}
