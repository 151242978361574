/**
 * Module dependencies.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { Banner } from 'src/api/entities/banners/types';
import { Container } from 'src/components/core/layout/container';
import { ContentBanner } from './shared/content-banner';
import { media } from 'src/styles/media';
import { useIsClient } from 'src/context/client';
import { useSessionStorage } from 'src/hooks/use-session-storage';
import styled from 'styled-components';

/**
 * Props type.
 */

type Props = {
  banner?: Banner;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(motion.div)`
  align-items: center;
  min-width: 100%;
  position: absolute;
  top: calc(
    var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 2
  );
  z-index: calc(var(--z-index-banner) - 1);

  ${media.min.xl`
    top: calc(var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + 16px * 2);
  `}
`;

/**
 * `StyledContentBanner` styled component.
 */

const StyledContentBanner = styled(ContentBanner)`
  border-radius: 16px;
  margin: 0 var(--gutter-cards);
  overflow: hidden;
`;

/**
 * Export `PageTopBanner` component.
 */

export const PageTopBanner = ({ banner }: Props) => {
  const isClient = useIsClient();
  const [hideBanner, setHideBanner] = useSessionStorage(`banner-${banner?.id}`);

  return (
    <AnimatePresence>
      {banner && (!banner.dismissable || (isClient && !hideBanner)) && (
        <Wrapper
          {...(banner.dismissable && { initial: { opacity: 0 } })}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Container>
            <StyledContentBanner {...banner} onClose={() => setHideBanner(true)} />
          </Container>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
