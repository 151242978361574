/**
 * Module dependencies.
 */

import { PropsWithChildren, createContext, useContext } from 'react';
import { Region } from 'src/core/constants/i18n';
import { Settings } from 'src/api/entities/settings/types';

/**
 * `Props` type.
 */

type Props<Slugs> = Settings & {
  locale: {
    languageCode: string;
    regionCode: Region;
  };
  slugs: Slugs;
  userCountry: string | null;
};

/**
 * `SettingsContext` context.
 */

const SettingsContext = createContext<Props<Set<string>>>(null!);

/**
 * Export `useSettings` hook.
 */

export const useSettings = () => useContext(SettingsContext);

/**
 * Export `SettingsProvider` provider.
 */

export const SettingsProvider = ({ children, slugs, ...rest }: PropsWithChildren<Props<string[]>>) => (
  <SettingsContext.Provider value={{ ...rest, slugs: new Set(slugs) }}>{children}</SettingsContext.Provider>
);
