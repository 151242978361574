/**
 * Module dependencies.
 */

import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import styled from 'styled-components';

/**
 * `ButtonProps` type.
 */

type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

/**
 * `Props` type.
 */

type Props = Omit<ButtonProps, 'ref'> & { innerRef?: Pick<ButtonProps, 'ref'> };

/**
 * `Button` styled component.
 */

export const Button = styled.button`
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  border: none;
  height: 24px;
  padding: 0;
  width: 24px;

  ::before,
  ::after,
  div {
    background: var(--color-primary);
    border-radius: 2px;
    content: '';
    display: block;
    height: 2px;
    margin: 4px 0;
    transition: transform var(--navbar-transition-default);
  }

  :not(:focus-visible) {
    outline: none;
  }

  &[aria-expanded='true'] {
    &::before {
      transform: translateY(6px) rotate(135deg);
    }

    &::after {
      transform: translateY(-6px) rotate(-135deg);
    }

    div {
      transform: scaleX(0);
      transition-duration: 200ms;
    }
  }
`;

/**
 * Export `MenuIcon` component.
 */

export function MenuIcon(props: Props) {
  return (
    <Button {...props}>
      <div />
    </Button>
  );
}
