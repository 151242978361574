/**
 * Module dependencies.
 */

import { Banner } from 'src/api/entities/banners/types';
import { CloseButton } from './close-button';
import { RichTextClean } from 'src/components/rich-text/clean';
import { Text } from 'src/components/core/text';
import { linkAnimatedStyles } from 'src/components/core/links';
import { media } from 'src/styles/media';
import { textStyles } from 'src/styles/typography';
import styled from 'styled-components';

/**
 * `DescriptionBannerProps` type.
 */

export type DescriptionBannerProps = Pick<
  Banner,
  'backgroundColor' | 'description' | 'dismissable' | 'dismissableColor' | 'textSize'
> & {
  onClose: () => void;
};

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  column-gap: 16px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 6px 16px 8px;
  position: relative;

  &[data-is-dismissable='true'] {
    grid-template-columns: 1fr auto;
  }

  ${media.min.ms`
    padding: 6px 24px;
  `}
`;

/**
 * `TextContent` styled component.
 */

const TextContent = styled(Text).attrs({ block: true, fontWeight: 400 })`
  &[data-size='large'] {
    ${textStyles.paragraph2}
    ${media.min.ms`${textStyles.paragraph1}`}
  }

  &[data-size='medium'] {
    ${textStyles.paragraph3}
    ${media.min.ms`${textStyles.paragraph2}`}
  }

  &[data-size='small'] {
    ${textStyles.small}
    ${media.min.ms`${textStyles.paragraph3}`}
  }

  a {
    ${linkAnimatedStyles}
  }
`;

/**
 * Export `DescriptionBanner` component.
 */

export const DescriptionBanner = ({
  backgroundColor,
  description,
  dismissable,
  dismissableColor,
  onClose,
  textSize = 'medium'
}: DescriptionBannerProps) => {
  return (
    <ContentWrapper data-is-dismissable={dismissable} {...(backgroundColor && { style: { backgroundColor } })}>
      <RichTextClean element={TextContent} elementProps={{ 'data-size': textSize }}>
        {description}
      </RichTextClean>

      {dismissable && <CloseButton dismissableColor={dismissableColor} onClose={onClose} />}
    </ContentWrapper>
  );
};
