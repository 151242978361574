/**
 * Module dependencies.
 */

import { regexes } from 'src/core/constants/regexes';

/**
 * Export `absoluteUrlResolve` util.
 */

export function absoluteUrlResolve(path?: string) {
  if (!path) {
    return;
  }

  return `${process.env.NEXT_PUBLIC_BASE_URL}${path.startsWith('/') ? path : `/${path}`}`;
}

/**
 * Export `urlPathToParts` util.
 *
 * @example
 * Input: '/en/path/secondary-path?query=params'
 * Output: ['en', 'path', 'secondary-path']
 */

export function urlPathToParts(urlPath: string) {
  const pathWithoutQueryParams = urlPath.split('?')[0];

  return typeof pathWithoutQueryParams === 'string'
    ? pathWithoutQueryParams.split('/').filter(value => !!value?.length)
    : [];
}

/**
 * Export `isExternalUrl` util.
 */

export function isExternalUrl(url: string): boolean {
  return regexes.url.test(url) || regexes.emailLink.test(url) || regexes.telLink.test(url);
}

/**
 * Export `isSameURL` util.
 */

export function isSameURL(target: URL, current: URL) {
  const cleanTarget = `${target.protocol}//${target.host}${target.pathname}${target.search}`;
  const cleanCurrent = `${current.protocol}//${current.host}${current.pathname}${current.search}`;

  return cleanTarget === cleanCurrent;
}

/**
 * Export `extractPath` util.
 */

export function extractPath(href: string) {
  const url = new URL(href);

  return `${url.pathname}${url.search}${url.hash}`;
}
