/**
 * Constants.
 */

const impactDomain = process.env.NEXT_PUBLIC_IMPACT_DOMAIN;
const impactId = process.env.NEXT_PUBLIC_IMPACT_ID;

/**
 * Export `impact`.
 */

export function impact() {
  const headScriptExists = document.getElementById('impactHeadScript');
  const bodyScriptExists = document.getElementById('impactBodyScript');

  if ((headScriptExists && bodyScriptExists) || !impactDomain || !impactId) {
    return;
  }

  const impactHeadScript = document.createElement('script');
  const impactBodyScript = document.createElement('script');

  impactHeadScript.id = 'impactHeadScript';
  impactHeadScript.async = true;
  impactHeadScript.innerHTML = `
    (function(a,b,c,d,e,f,g){
      e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};
      f=d.createElement(b);
      g=d.getElementsByTagName(b)[0];
      f.async=1;
      f.src=a;
      g.parentNode.insertBefore(f,g);
    })('${impactDomain}/${impactId}.js','script','ire',document,window);
  `;

  impactBodyScript.id = 'impactBodyScript';
  impactBodyScript.async = true;
  impactBodyScript.innerHTML = `
    ire('identify', { customerId: '', customerEmail: '' });
  `;

  document.body.appendChild(impactHeadScript);
  document.body.appendChild(impactBodyScript);
}
