/**
 * Module dependencies.
 */

import { Banner } from 'src/api/entities/banners/types';
import { Svg } from 'src/components/core/svg';
import closeIcon from 'src/assets/svgs/16/close.svg';
import styled from 'styled-components';

/**
 * `CloseButtonProps` type.
 */

export type CloseButtonProps = {
  className?: string;
  dismissableColor?: Banner['dismissableColor'];
  onClose: () => void;
};

/**
 * `CloseIcon` styled component.
 */

const CloseIcon = styled(Svg).attrs({
  icon: closeIcon,
  size: '16px'
})`
  cursor: pointer;
  transition: opacity var(--transition-fast);

  :hover {
    opacity: 0.7;
  }
`;

/**
 * Export `CloseButton` component.
 */

export const CloseButton = ({ className, dismissableColor, onClose }: CloseButtonProps) => (
  <CloseIcon
    className={className}
    color={dismissableColor ?? 'var(--color-neutral105)'}
    onClick={onClose}
    role={'button'}
    tabIndex={0}
  />
);
