/**
 * Module dependencies.
 */

import { useBreakpoint } from './use-breakpoint';
import { useSettings } from 'src/context/settings';

/**
 * Export `appModalId` constant.
 */

export const appModalId = 'navbar-modal-button';

/**
 * Export `useUpholdAppUrl` hook.
 */

export function useUpholdAppUrl(href: string | undefined) {
  const isMobile = useBreakpoint(0, 'md');
  const settings = useSettings();
  const appUrl = settings?.regionSettings?.modalGettingStarted?.appUrl;

  return isMobile && href?.slice(1) === appModalId ? { href: appUrl, onClick: undefined, target: '_blank' } : {};
}
