/**
 * Constants.
 */

const tradeDeskAdvertiserId = process.env.NEXT_PUBLIC_TRADE_DESK_ADVERTISER_ID;
const tradeDeskPixelId = process.env.NEXT_PUBLIC_TRADE_DESK_PIXEL_ID;

/**
 * Export `tradeDesk`.
 */

export function tradeDesk() {
  const scriptExists = document.getElementById('tradeDeskScript');

  if (scriptExists || !tradeDeskAdvertiserId || !tradeDeskPixelId) {
    return;
  }

  const tradeDeskScript = document.createElement('script');

  tradeDeskScript.id = 'tradeDeskScript';
  tradeDeskScript.async = true;
  tradeDeskScript.src = 'https://js.adsrvr.org/up_loader.1.1.0.js';

  tradeDeskScript.onload = () => {
    // eslint-disable-next-line
    ttd_dom_ready(() => {
      if (typeof TTDUniversalPixelApi === 'function') {
        // eslint-disable-next-line
        var universalPixelApi = new TTDUniversalPixelApi();
        universalPixelApi.init(
          tradeDeskAdvertiserId,
          [tradeDeskPixelId],
          'https://insight.adsrvr.org/track/up'
        );
      }
    });
  }

  document.head.appendChild(tradeDeskScript);
}
