/**
 * Module dependencies.
 */

import { AnimationStatus } from 'src/components/layout/page-transition';
import { isSameURL } from 'src/core/utils/url';
import { transparentize } from 'src/styles/utils/colors';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import styled, { keyframes } from 'styled-components';

/**
 * Constants.
 */

const id = 'progress-bar-container';
const shineEffect = keyframes`to { left: 100%; }`;

/**
 * `Props` type.
 */

type Props = {
  status: AnimationStatus;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  inset: 0;
  position: absolute;
  z-index: 1;

  #nprogress {
    .bar,
    .peg {
      height: 100%;
      position: absolute;
    }

    .bar {
      background: ${transparentize('primaryForDark20', 0.2)};
      overflow: hidden;
      width: 100%;

      &,
      &::before {
        height: 100%;
        left: 0;
        top: 0;
      }

      &::before {
        animation-duration: 525ms;
        animation-fill-mode: forwards;
        animation-name: ${shineEffect};
        animation-play-state: paused;
        animation-timing-function: var(--transition-animation);
        background: linear-gradient(
          0.25turn,
          transparent,
          ${transparentize('white', 0.05)},
          ${transparentize('white', 0.1)},
          ${transparentize('white', 0.15)},
          ${transparentize('primaryForDark20', 0.05)},
          transparent
        );
        content: '';
        display: block;
        position: absolute;
        width: 60%;
      }
    }

    .peg {
      display: block;
      opacity: 1;
      right: 0;
      width: 100px;
    }
  }

  &[data-complete-transition='true'] {
    #nprogress .bar::before {
      animation-play-state: running;
    }
  }
`;

/**
 * Export `ProgressBar` component.
 */

export const ProgressBar = ({ status }: Props) => {
  const router = useRouter();

  NProgress.configure({
    minimum: 0.3,
    parent: `#${id}`,
    showSpinner: false,
    speed: 600,
    trickle: true,
    trickleSpeed: 500
  });

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleRouteStart = (url: string) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const targetUrl = new URL(url, window.location.href);
      const currentUrl = new URL(router.route, window.location.href);

      if (!isSameURL(targetUrl, currentUrl)) {
        // To prevent the progressbar being triggered whenever the page takes less than 500ms to change.
        timeoutId = setTimeout(() => {
          NProgress.start();
        }, 500);
      }
    };

    const handleRouteDone = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      NProgress.done();
    };

    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteDone);
    router.events.on('routeChangeError', handleRouteDone);

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', handleRouteDone);
      router.events.off('routeChangeError', handleRouteDone);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [router.events, router.route]);

  return (
    <Wrapper
      data-complete-transition={status === 'end'}
      id={id}
      {...(status === 'idle' && { style: { pointerEvents: 'none' } })}
    />
  );
};
