/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef } from 'react';
import { colors } from 'src/styles/colors';
import { ifProp, switchProp } from 'styled-tools';
import { textStyles } from 'src/styles/typography';
import styled from 'styled-components';

/**
 * Export `TextProps` type.
 */

export type TextProps = ComponentPropsWithoutRef<'span'> & {
  as?:
    | 'a'
    | 'address'
    | 'b'
    | 'blockquote'
    | 'div'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'i'
    | 'label'
    | 'p'
    | 'small'
    | 'strong'
    | 'sup'
    | 'u';
  block?: boolean;
  color?: keyof typeof colors;
  fontWeight?: 400 | 500 | 700;
  variant?: keyof typeof textStyles;
};

/**
 * Export `Text` styled component.
 */

export const Text = styled.span<TextProps>`
  ${switchProp('variant', textStyles, textStyles.paragraph1)}
  ${ifProp('block', 'display: block;')}
  ${({ color }) => color && `color: var(--color-${color});`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
`;
