/**
 * Module dependencies.
 */

import { RefObject, useEffect } from 'react';

/**
 * Export `useDetectOutsideClick` hook.
 */

export function useDetectOutsideClick(ref: RefObject<HTMLElement> | null | undefined, calllback?: () => void) {
  useEffect(() => {
    if (!calllback || !ref?.current) {
      return;
    }

    const handleClickOutside = ({ target }: MouseEvent) => {
      if (target instanceof Element && !ref?.current?.contains(target)) {
        calllback();
      }
    };

    document.addEventListener('click', handleClickOutside, false);

    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [calllback, ref]);
}
