/**
 * Module dependencies.
 */

import { Text, TextProps } from 'src/components/core/text';
import { media } from 'src/styles/media';
import styled, { css } from 'styled-components';

/**
 * Export `headingStyles` constant.
 */

export const headingStyles = css`
  .highlight {
    color: var(--color-primary);
  }
`;

/**
 * Export `Pretitle` styled component.
 */

export const Pretitle = styled(Text).attrs({ as: 'h3', variant: 'heading4' })`
  font-weight: 500;

  ${headingStyles}
`;

/**
 * Export `Title` styled component.
 */

export const Title = styled(Text).attrs((props: TextProps) => ({
  ...props,
  as: props.as || 'h4',
  variant: 'heading1'
}))`
  font-weight: 500;
  margin: 0 auto;
  max-width: 960px;

  ${headingStyles}

  ${media.min.md`
    margin-bottom: 8px;
  `}
`;

/**
 * Export `Description` styled component.
 */

export const Description = styled(Text).attrs({ as: 'p', variant: 'subtitle2' })`
  font-weight: 400;
  margin: 0 auto;
  max-width: 776px;

  ${headingStyles}

  ${media.min.md`
    margin-bottom: 8px;
  `}
`;
