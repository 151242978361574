/**
 * Module dependencies.
 */

import { transparentize } from 'src/styles/utils/colors';

/**
 * Export `buttonThemesConfig` constant.
 */

export const buttonThemesConfig: Record<
  'dark' | 'light',
  Record<'primary' | 'neutral', Record<'idle' | 'hover' | 'active' | 'disabled', Record<string, string>>>
> = {
  dark: {
    neutral: {
      active: {
        backgroundColor: transparentize('neutral70', 0.72),
        textColor: 'var(--color-primaryForDark)'
      },
      disabled: {
        backgroundColor: transparentize('neutral80', 0.72),
        textColor: 'var(--color-neutral60)'
      },
      hover: {
        backgroundColor: transparentize('neutral60', 0.72),
        textColor: 'var(--color-primaryForDark)'
      },
      idle: {
        backgroundColor: transparentize('neutral80', 0.72),
        textColor: 'var(--color-primaryForDark)'
      }
    },
    primary: {
      active: {
        backgroundColor: transparentize('primaryForDark50', 0.72),
        textColor: 'var(--color-neutral105)'
      },
      disabled: {
        backgroundColor: transparentize('primaryForLight50', 0.72),
        textColor: transparentize('neutral105', 0.72)
      },
      hover: {
        backgroundColor: transparentize('primaryForDark40', 0.72),
        textColor: 'var(--color-neutral105)'
      },
      idle: {
        backgroundColor: transparentize('primaryForDark30', 0.72),
        textColor: 'var(--color-neutral105)'
      }
    }
  },
  light: {
    neutral: {
      active: {
        backgroundColor: transparentize('neutral10', 0.72),
        textColor: 'var(--color-primary)'
      },
      disabled: {
        backgroundColor: transparentize('neutral05', 0.72),
        textColor: 'var(--color-neutral30)'
      },
      hover: {
        backgroundColor: transparentize('neutral05', 0.72),
        textColor: 'var(--color-primary)'
      },
      idle: {
        backgroundColor: transparentize('white', 0.72),
        textColor: 'var(--color-primary)'
      }
    },
    primary: {
      active: {
        backgroundColor: transparentize('primaryForLight70', 0.72),
        textColor: 'var(--color-white)'
      },
      disabled: {
        backgroundColor: transparentize('primaryForLight50', 0.72),
        textColor: transparentize('white', 0.72)
      },
      hover: {
        backgroundColor: transparentize('primaryForLight60', 0.72),
        textColor: 'var(--color-white)'
      },
      idle: {
        backgroundColor: transparentize('primaryForLight50', 0.72),
        textColor: 'var(--color-white)'
      }
    }
  }
};

/**
 * Export `buttonThemes` constant.
 */

export const buttonThemes = (['neutral', 'primary'] as const).map(
  variant => `
    &[data-variant='${variant}'] {
      --button-background-color: ${buttonThemesConfig.light[variant].idle.backgroundColor};
      --button-text-color: ${buttonThemesConfig.light[variant].idle.textColor};
      --button-active-background-color: ${buttonThemesConfig.light[variant].active.backgroundColor};
      --button-active-text-color: ${buttonThemesConfig.light[variant].active.textColor};
      --button-hover-background-color: ${buttonThemesConfig.light[variant].hover.backgroundColor};
      --button-hover-text-color: ${buttonThemesConfig.light[variant].hover.textColor};
      --button-disabled-background-color: ${buttonThemesConfig.light[variant].disabled.backgroundColor};
      --button-disabled-text-color: ${buttonThemesConfig.light[variant].disabled.textColor};
    }

    [data-theme='dark'] [data-theme='dark'] &[data-variant='${variant}'],
    [data-theme='light'] [data-theme='dark'] &[data-variant='${variant}'],
    [data-theme='dark'] [data-theme='inherit'] &[data-variant='${variant}'] {
      --button-background-color: ${buttonThemesConfig.dark[variant].idle.backgroundColor};
      --button-text-color: ${buttonThemesConfig.dark[variant].idle.textColor};
      --button-active-background-color: ${buttonThemesConfig.dark[variant].active.backgroundColor};
      --button-active-text-color: ${buttonThemesConfig.dark[variant].active.textColor};
      --button-hover-background-color: ${buttonThemesConfig.dark[variant].hover.backgroundColor};
      --button-hover-text-color: ${buttonThemesConfig.dark[variant].hover.textColor};
      --button-disabled-background-color: ${buttonThemesConfig.dark[variant].disabled.backgroundColor};
      --button-disabled-text-color: ${buttonThemesConfig.dark[variant].disabled.textColor};
    }
  `
);
