/**
 * Module dependencies.
 */

import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';

/**
 * `BatteryContext` context.
 */

const BatteryContext = createContext<{ isLow?: boolean }>(null!);

/**
 * Export `useBattery` hook.
 */

export const useBattery = () => useContext(BatteryContext);

/**
 * Export `BatteryProvider` provider.
 */

export const BatteryProvider = ({ children }: PropsWithChildren) => {
  const [battery, setBattery] = useState<any>();
  const [isCharging, setIsCharging] = useState<boolean>();
  const [level, setLevel] = useState<number>();

  const isLow = useMemo(() => {
    if (isCharging === undefined || level === undefined) {
      return;
    }

    return !isCharging && level < 0.15;
  }, [isCharging, level]);

  const ignoreBattery = () => {
    setIsCharging(false);
    setLevel(1);
  };

  useEffect(() => {
    try {
      // @ts-expect-error - BatteryManager is not standardized
      navigator.getBattery().then(battery => setBattery(battery));
    } catch {
      ignoreBattery();
    }
  }, []);

  useEffect(() => {
    if (!battery) {
      return;
    }

    try {
      const updateCharging = () => setIsCharging(battery.charging);
      const updateLevel = () => setLevel(battery.level);

      battery.addEventListener('chargingchange', updateCharging);
      battery.addEventListener('levelchange', updateLevel);

      updateCharging();
      updateLevel();

      return () => {
        battery.removeEventListener('chargingchange', updateCharging);
        battery.removeEventListener('levelchange', updateLevel);
      };
    } catch {
      ignoreBattery();
    }
  }, [battery]);

  return <BatteryContext.Provider value={{ isLow }}>{children}</BatteryContext.Provider>;
};
