/**
 * Module dependencies.
 */

import { AppProps } from 'src/types/app';
import { ApplicationScripts } from 'src/components/application-scripts';
import { BatteryProvider } from 'src/context/battery';
import { ClientProvider } from 'src/context/client';
import { DefaultSeo } from 'next-seo';
import { ErrorBoundary } from 'src/components/core/error/error-boundary';
import { GlobalStyle } from 'src/styles/global';
import { HevcAlphaProvider } from 'src/context/hevc-alpha';
import { I18nProvider } from 'src/context/i18n';
import { Layout } from 'src/components/layout';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { Region } from 'src/core/constants/i18n';
import { SettingsProvider } from 'src/context/settings';
import { ToastProvider } from 'src/context/toast';
import { axiosNextInstance } from 'src/api/lib/next';
import { colors } from 'src/styles/colors';
import { seoDefaultConfig } from 'src/core/constants/seo-default-config';
import { useEffect } from 'react';
import Head from 'next/head';

/**
 * Constants.
 */

const defaultRegion = process.env.DEFAULT_REGION;
const defaultLanguage = process.env.DEFAULT_LANGUAGE;
const googleSiteVerification = process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION;

/**
 * Query Client.
 */

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity
    }
  }
});

/**
 * `getUserGeoLocation` handler.
 */

async function getUserGeoLocation(): Promise<string | null> {
  const { data } = await axiosNextInstance.get<{ country: string }>('geo-location');

  return data.country ?? null;
}

/**
 * `Main` component.
 */

const Main = ({ Component, pageProps, router }: AppProps) => {
  const { banners, footer, locale, navbar, settings, slugs, ...rest } = pageProps;
  const defaultLocale = `${defaultLanguage}-${defaultRegion}`;
  const [languageCode, regionCode] = (locale ?? defaultLocale)?.split('-') as [string, Region];
  const { data: userCountry } = useQuery({ queryFn: getUserGeoLocation, queryKey: ['user-country'] });

  return (
    <ClientProvider>
      <BatteryProvider>
        <HevcAlphaProvider>
          <SettingsProvider
            {...settings}
            locale={{ languageCode, regionCode }}
            slugs={slugs}
            userCountry={userCountry ?? null}
          >
            <I18nProvider i18n={settings?.globalSettings?.i18n} language={languageCode}>
              <Layout banners={banners} pageFooter={footer} pageNavbar={navbar}>
                <Component {...rest} key={router.route} settings={settings} />
              </Layout>
            </I18nProvider>
          </SettingsProvider>
        </HevcAlphaProvider>
      </BatteryProvider>
    </ClientProvider>
  );
};

/**
 * Export `App` component.
 */

export default function App(appProps: AppProps) {
  useEffect(() => {
    document.documentElement.setAttribute('lang', appProps?.pageProps?.locale?.split('-')?.[0]);
  }, [appProps?.pageProps?.locale]);

  return (
    <>
      <Head>
        <meta charSet={'UTF-8'} />

        <meta content={'IE=edge'} httpEquiv={'X-UA-Compatible'} />

        <meta content={'width=device-width, initial-scale=1'} name={'viewport'} />

        <meta content={'true'} name={'HandheldFriendly'} />

        <link href={'/favicon.ico'} rel={'shortcut icon'} type={'image/x-icon'} />

        <link href={'/favicons/apple-touch-icon.png'} rel={'apple-touch-icon'} sizes={'180x180'} />

        <link href={'/favicons/favicon-32x32.png'} rel={'icon'} sizes={'32x32'} type={'image/png'} />

        <link href={'/favicons/favicon-16x16.png'} rel={'icon'} sizes={'16x16'} type={'image/png'} />

        <link href={'/site.webmanifest'} rel={'manifest'} />

        <link color={colors.primary} href={'/favicons/safari-pinned-tab.svg'} rel={'mask-icon'} />

        <meta content={colors.primary} name={'msapplication-TileColor'} />

        <meta content={colors.primary} name={'theme-color'} />

        {googleSiteVerification && <meta content={googleSiteVerification} name={'google-site-verification'} />}
      </Head>

      <ApplicationScripts />

      <GlobalStyle />

      <DefaultSeo {...seoDefaultConfig} />

      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Main {...appProps} />
        </QueryClientProvider>
      </ErrorBoundary>

      <ToastProvider />
    </>
  );
}
