/**
 * Module dependencies.
 */

import { extractPath } from 'src/core/utils/url';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

/**
 * Export `HrefLang` type.
 */

export type HrefLang = {
  locale: string;
  route: string;
};

/**
 * `getHreflangs`.
 */

function getHreflangs(): HrefLang[] {
  const hreflangElements = Array.from(document.querySelectorAll('link[hreflang]'));

  return hreflangElements.map(element => ({
    locale: element.getAttribute('hreflang') || '',
    route: extractPath(element.getAttribute('href') || '')
  }));
}

/**
 * Export `useHreflangs` hook.
 */

export function useHreflangs() {
  const [hreflangs, setHreflangs] = useState<HrefLang[]>([]);
  const router = useRouter();

  useEffect(() => {
    setHreflangs(getHreflangs());
  }, [router]);

  return hreflangs;
}
