/**
 * Module dependencies.
 */

import { ModalPortal } from './portal';
import { RawHtml } from 'src/components/core/raw-html';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { fadeIn, fadeInUp, fadeOut, fadeOutDown } from 'src/styles/keyframes';
import { transparentize } from 'src/styles/utils/colors';
import { useKeyPress } from 'src/hooks/use-key-press';
import React, { ReactNode, useEffect, useRef } from 'react';
import closeSvg from 'src/assets/svgs/24/close.svg';
import styled from 'styled-components';

/**
 * Constants.
 */

const durationMs = 350;
const delayMs = 175;

/**
 * Export `ModalProps` types.
 */

export type ModalProps = {
  children?: ReactNode;
  className?: string;
  isOpen?: boolean;
  onRequestClose: () => void;
  title?: string;
};

/**
 * `Underlay` styled component.
 */

const Underlay = styled.div`
  --modal-animation-duration: ${durationMs}ms;
  --modal-animation-fill-mode: both;
  --modal-animation-timing-function: var(--transition-animation);
  --modal-underlay-animation-delay: ${delayMs}ms;
  --modal-underlay-animation-name: ${fadeOut};
  --modal-body-animation-delay: 0;
  --modal-body-animation-name: ${fadeOutDown};
  --modal-close-button-size: 2.5rem;
  --modal-close-button-background-color: var(--color-neutral80);
  --modal-close-button-color: var(--color-white);
  --modal-close-button-active-color: var(--color-neutral60);

  align-items: center;
  animation-delay: var(--modal-underlay-animation-delay);
  animation-duration: var(--modal-animation-duration);
  animation-fill-mode: var(--modal-animation-fill-mode);
  animation-name: var(--modal-underlay-animation-name);
  animation-timing-function: var(--modal-animation-timing-function);
  backdrop-filter: blur(8px);
  background-color: ${transparentize('neutral95', 0.9)};
  display: flex;
  inset: 0;
  justify-content: center;
  opacity: 0;
  overflow-y: auto;
  position: fixed;
  scroll-snap-type: y mandatory;
  z-index: var(--z-index-modal-underlay);

  &[data-active='true'] {
    --modal-body-animation-delay: ${delayMs}ms;
    --modal-body-animation-name: ${fadeInUp};
    --modal-underlay-animation-delay: 0;
    --modal-underlay-animation-name: ${fadeIn};
  }
`;

/**
 * `ModalBody` styled component.
 */

const ModalBody = styled.div`
  animation-delay: var(--modal-body-animation-delay);
  animation-duration: var(--modal-animation-duration);
  animation-fill-mode: var(--modal-animation-fill-mode);
  animation-name: var(--modal-body-animation-name);
  animation-timing-function: var(--modal-animation-timing-function);
  border-radius: var(--border-radius);
  color: var(--color-white);
  height: clamp(320px, 80%, 640px);
  opacity: 0;
  width: clamp(320px, 100%, 880px);
`;

/**
 * `Head` styled component.
 */

const Head = styled.div`
  align-items: center;
  display: flex;
  min-height: 40px;
  padding: 40px;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  padding: 0 40px 40px;
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled.button`
  -webkit-tap-highlight-color: transparent;
  background-color: var(--modal-close-button-background-color);
  border-radius: var(--modal-close-button-size);
  border-width: 0;
  color: var(--modal-close-button-color);
  cursor: pointer;
  display: inline-flex;
  height: var(--modal-close-button-size);
  justify-content: center;
  outline: none;
  padding: 0;
  place-items: center;
  position: absolute;
  right: 40px;
  top: 40px;
  transition: background-color var(--transition-default);
  white-space: nowrap;
  width: var(--modal-close-button-size);

  &:focus,
  &:hover {
    background-color: var(--modal-close-button-active-color);
  }
`;

/**
 * Export `Modal` component.
 */

export function Modal(props: ModalProps) {
  const { children, className, isOpen, onRequestClose, title } = props;
  const modalRef = useRef(null);

  useKeyPress('Escape', () => {
    if (isOpen) {
      onRequestClose();
    }
  });

  useKeyPress('Tab', event => {
    // Handling trapped tab focus.
    const modalElement = modalRef.current;

    if (!isOpen || !modalElement) {
      return;
    }

    const focusableElements = [
      ...(modalElement as HTMLElement).querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )
    ];

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];
    const shouldFocusFist = !event.shiftKey && document.activeElement === lastElement;
    const isFocusOutside = focusableElements.every(element => element !== document.activeElement);

    if (isFocusOutside || shouldFocusFist) {
      event.preventDefault();
      (firstElement as HTMLElement)?.focus?.();

      return;
    }

    if (event.shiftKey && document.activeElement === firstElement) {
      event.preventDefault();
      (lastElement as HTMLElement)?.focus?.();
    }
  });

  useEffect(() => {
    if (isOpen) {
      // Removes the blur on the trigger element.
      (document.activeElement as null | HTMLElement)?.blur?.();
    }
  }, [isOpen]);

  return (
    <ModalPortal isOpen={!!isOpen} preventBodyScroll={!!isOpen} timeout={durationMs + delayMs}>
      <Underlay
        data-active={isOpen}
        onClick={event => {
          event.stopPropagation();
          onRequestClose();
        }}
        ref={modalRef}
        tabIndex={-1}
      >
        <ModalBody className={className} onClick={event => event.stopPropagation()} role={'dialog'}>
          <Head>
            <Text variant={'subtitle2'}>
              <RawHtml>{title}</RawHtml>
            </Text>

            <CloseButton onClick={onRequestClose}>
              <Svg icon={closeSvg} size={'24px'} />
            </CloseButton>
          </Head>

          <Content>{children}</Content>
        </ModalBody>
      </Underlay>
    </ModalPortal>
  );
}
