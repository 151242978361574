/**
 * Module dependencies.
 */

import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

/**
 * `ClientContext` context.
 */

const ClientContext = createContext(false);

/**
 * Export `useIsClient` hook.
 */

export const useIsClient = () => useContext(ClientContext);

/**
 * Export `ClientProvider` provider.
 */

export const ClientProvider = ({ children }: PropsWithChildren) => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return <ClientContext.Provider value={isClient}>{children}</ClientContext.Provider>;
};
