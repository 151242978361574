/**
 * Module dependencies.
 */

import { useCallback, useEffect } from 'react';

/**
 * Export `useKeyPress` hook.
 */

export function useKeyPress(keyCodes: string | string[], callback: (event: KeyboardEvent) => void): void {
  const handler = useCallback(
    (event: KeyboardEvent) => {
      const keyCodesArray = Array.isArray(keyCodes) ? keyCodes : [keyCodes];

      if (keyCodesArray.includes(event.code)) {
        callback(event);
      }
    },
    [callback, keyCodes]
  );

  useEffect(() => {
    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [handler]);
}
