/**
 * `palette` constant.
 */

const palette = {
  black: '#000000',
  critical10: '#ffd8cc',
  critical20: '#ffb7a3',
  critical30: '#ff9c85',
  critical40: '#ff8366',
  critical50: '#ff6e4c',
  critical60: '#fe5836',
  critical70: '#d54020',
  critical80: '#ae2c0f',
  critical90: '#831b0b',
  info05: '#e0ecff',
  info10: '#c2d9ff',
  info20: '#a3c8ff',
  info30: '#7ab2ff',
  info40: '#579dff',
  info50: '#3388ff',
  info60: '#196ff0',
  info70: '#0a57c2',
  info80: '#07439d',
  neutral0: '#f7f8fc',
  neutral05: '#ebecf5',
  neutral10: '#d5d7e7',
  neutral20: '#bcc2dc',
  neutral30: '#a5b0cf',
  neutral40: '#8897bf',
  neutral50: '#7384b0',
  neutral60: '#495a83',
  neutral70: '#35415f',
  neutral80: '#273149',
  neutral90: '#1d2738',
  neutral95: '#141c29',
  neutral100: '#0f141f',
  neutral105: '#090d14',
  primaryForDark0: '#eeffeb',
  primaryForDark05: '#d6fed2',
  primaryForDark10: '#acfca6',
  primaryForDark20: '#84fb7f',
  primaryForDark30: '#62e75f',
  primaryForDark40: '#52ce50',
  primaryForDark50: '#35b13d',
  primaryForDark60: '#22872c',
  primaryForDark80: '#065511',
  primaryForLight0: '#f0fff3',
  primaryForLight05: '#d6ffe0',
  primaryForLight10: '#b1fcc3',
  primaryForLight20: '#8ef5a6',
  primaryForLight30: '#6fe68a',
  primaryForLight40: '#49cc68',
  primaryForLight50: '#39a854',
  primaryForLight60: '#2a8440',
  primaryForLight70: '#1d662e',
  warning05: '#fee7c3',
  warning10: '#fdd491',
  warning20: '#fac461',
  warning30: '#f5b73d',
  warning40: '#e7a523',
  warning50: '#c98e0d',
  warning60: '#9c6e07',
  warning70: '#765205',
  warning80: '#593d03',
  white: '#ffffff'
};

/**
 * Export `colors`.
 */

export const colors = {
  ...palette,
  critical: palette.critical60,
  info: palette.info50,
  neutral: palette.neutral95,
  primary: palette.primaryForLight40,
  primaryForDark: palette.primaryForDark20,
  primaryForLight: palette.primaryForLight40,
  warning: palette.warning40
};
