/**
 * Module dependencies.
 */

import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

/**
 * `HevcAlphaContext` context.
 */

const HevcAlphaContext = createContext<boolean | undefined>(undefined);

/**
 * Export `useSupportsHevcAlpha` hook.
 */

export const useSupportsHevcAlpha = () => useContext(HevcAlphaContext);

/**
 * Export `HevcAlphaProvider` provider.
 */

export const HevcAlphaProvider = ({ children }: PropsWithChildren) => {
  const [isWebkit, setIsWebkit] = useState<boolean>();

  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase();
    const hasMediaCapabilities = !!(navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo);
    const isSafari = ua.includes('safari') && !ua.includes('chrome') && ua.includes('version/');
    setIsWebkit(isSafari && hasMediaCapabilities);
  }, []);

  return <HevcAlphaContext.Provider value={isWebkit}>{children}</HevcAlphaContext.Provider>;
};
