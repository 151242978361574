/**
 * Module dependencies.
 */

import { css } from 'styled-components';

/**
 * Scroll animations.
 */

export const scrollAnimations = {
  blurFadeInVertical: 'blur-fade-in-vertical',
  fadeIn: 'fade-in',
  fadeInVertical: 'fade-in-vertical'
} as const;

/**
 * Animation delays.
 *
 * Generates delays 50-2000.
 */

export const animationDelays = Array.from(Array(20).keys())
  .reduce((previous, key) => {
    const value = key * 100 + 100;

    return `${previous}
    [data-scroll-delay='${value - 50}'] { transition-delay: ${value - 50}ms; }
    [data-scroll-delay='${value}'] { transition-delay: ${value}ms; }
  `;
  }, '')
  .trim();

/**
 * Export `scrollAnimationsStyles` styles.
 */

export const scrollAnimationsStyles = css`
  [data-scroll='${scrollAnimations.blurFadeInVertical}'] {
    transition: var(--transition-default);
    transition-property: all;
    will-change: opacity, transform, filter;

    &[data-scroll-position='outview-above'] {
      filter: blur(16px);
      opacity: 0;
      transform: translateY(max(-20%, -45px)) scale(0.85);
    }

    &[data-scroll-position='outview-below'] {
      filter: blur(16px);
      opacity: 0;
      transform: translateY(max(20%, 45px)) scale(0.85);
    }

    &[data-scroll-position='inview-from-below'],
    &[data-scroll-position='inview-from-above'] {
      filter: blur(0);
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  [data-scroll='${scrollAnimations.fadeInVertical}'] {
    transition: var(--transition-default);
    transition-property: all;
    will-change: opacity, transform;

    &[data-scroll-position='outview-above'] {
      opacity: 0;
      transform: translateY(max(-20%, -45px)) scale(0.85);
    }

    &[data-scroll-position='outview-below'] {
      opacity: 0;
      transform: translateY(max(20%, 45px)) scale(0.85);
    }

    &[data-scroll-position='inview-from-below'],
    &[data-scroll-position='inview-from-above'] {
      opacity: 1;
      transform: translate(0, 0) scale(1);
    }
  }

  [data-scroll='${scrollAnimations.fadeIn}'] {
    transition: var(--transition-default);
    transition-property: opacity;
    will-change: opacity;

    &[data-scroll-position='outview-above'],
    &[data-scroll-position='outview-below'] {
      opacity: 0;
    }

    &[data-scroll-position='inview-from-below'],
    &[data-scroll-position='inview-from-above'] {
      opacity: 1;
    }
  }
`;
