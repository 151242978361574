/**
 * Module dependencies.
 */

import { Image } from 'src/components/core/image';
import { LinkBlock } from './link-block';
import { Modal } from 'src/components/core/modal';
import { QRCode } from 'src/components/core/qr-code';
import { RawHtml } from 'src/components/core/raw-html/index';
import { Text } from 'src/components/core/text';
import { appModalId } from 'src/hooks/use-uphold-app-url';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useEffect, useState } from 'react';
import { useSettings } from 'src/context/settings';
import styled from 'styled-components';

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  --modal-grid-padding: 32px;

  color: var(--color-neutral0);
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 2fr 1fr;
`;

/**
 * `Subgrid` styled component.
 */

const Subgrid = styled.div`
  background-color: ${transparentize('neutral05', 0.05)};
  border-radius: var(--border-radius);
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
`;

/**
 * `Subtitle` styled component.
 */

const Subtitle = styled(Text).attrs({ variant: 'subtitle2' })``;

/**
 * `HeadingSquare` styled component.
 */

const HeadingSquare = styled.div`
  border-right: 1px solid ${transparentize('neutral05', 0.08)};
  display: flex;
  flex-direction: column;
  grid-row: 1 / -1;
  justify-content: flex-end;
  padding: var(--modal-grid-padding);

  b,
  strong {
    color: var(--color-primaryForDark20);
  }
`;

/**
 * `GreenCard` styled component.
 */

const GreenCard = styled.div`
  color: var(--color-neutral105);
  display: flex;
  flex-direction: column;
  padding: var(--modal-grid-padding);
  transition: 250ms var(--transition-animation);
  transition-delay: 125ms;
  transition-property: opacity, transform;
`;

/**
 * `GreenCardOverlay` styled component.
 */

const GreenCardOverlay = styled(GreenCard)`
  inset: 0;
  opacity: 0;
  padding-bottom: 0;
  position: absolute;
  transform: translateY(16px);
  transition-delay: 0ms;
`;

/**
 * `RightCard` styled component.
 */

const RightCard = styled.div`
  background-color: var(--color-primaryForDark);
  border-radius: var(--border-radius);
  font-weight: 400;
  overflow: hidden;
  position: relative;

  :hover,
  :focus-within,
  :focus-visible {
    ${GreenCard} {
      opacity: 0;
      transform: translateY(16px);
      transition-delay: 0ms;
    }

    ${GreenCardOverlay} {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 150ms;
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  flex: 1;
  position: relative;

  img {
    object-position: bottom center;
  }
`;

/**
 * `StyledQRCode` styled component.
 */

const StyledQRCode = styled(QRCode)`
  margin-bottom: 80px;
`;

/**
 * Export `GetStartedModal` component.
 */

export function GetStartedModal() {
  const [isOpen, setIsOpen] = useState(false);
  const { regionSettings } = useSettings();
  const { modalGettingStarted } = regionSettings;
  const isDesktop = useBreakpoint('md');

  useEffect(() => {
    if (!isDesktop) {
      setIsOpen(false);

      return;
    }
  }, [isDesktop]);

  if (!modalGettingStarted || !isDesktop) {
    return null;
  }

  return (
    <>
      <button id={appModalId} onClick={() => setIsOpen(true)} style={{ display: 'none' }} />

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        title={modalGettingStarted?.translations?.modalTitle}
      >
        <Grid>
          <Subgrid>
            <HeadingSquare>
              <Text block variant={'subtitle1'}>
                <RawHtml>{modalGettingStarted?.translations?.title}</RawHtml>
              </Text>
            </HeadingSquare>

            <LinkBlock
              href={modalGettingStarted?.signUpUrl}
              subtitle={modalGettingStarted?.translations?.signUpSubtitle}
              title={modalGettingStarted?.translations?.signUpTitle}
            />

            <LinkBlock
              href={modalGettingStarted?.signInUrl}
              subtitle={modalGettingStarted?.translations?.signInSubtitle}
              title={modalGettingStarted?.translations?.signInTitle}
            />
          </Subgrid>

          <RightCard>
            <GreenCard>
              <StyledQRCode value={modalGettingStarted?.appUrl} />

              <RawHtml element={Subtitle}>{modalGettingStarted?.translations?.downloadLead}</RawHtml>
            </GreenCard>

            <GreenCardOverlay>
              <RawHtml element={Subtitle}>{modalGettingStarted?.translations?.downloadHoverLead}</RawHtml>

              <ImageWrapper>
                {modalGettingStarted?.asset && (
                  <Image
                    alt={modalGettingStarted?.asset?.alt}
                    fill
                    src={modalGettingStarted?.asset?.url}
                    style={{ objectFit: 'contain', objectPosition: 'bottom' }}
                  />
                )}
              </ImageWrapper>
            </GreenCardOverlay>
          </RightCard>
        </Grid>
      </Modal>
    </>
  );
}
