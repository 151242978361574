/**
 * Module dependencies.
 */

import { MutableRefObject, RefCallback } from 'react';

/**
 * Export `mergeRefs` util.
 */

export const mergeRefs = <T>(...refs: Array<RefCallback<T | null> | MutableRefObject<T | null> | null>) => {
  const filteredRefs = refs.filter(Boolean);

  if (!filteredRefs.length) {
    return null;
  }

  if (filteredRefs.length === 1) {
    return filteredRefs[0];
  }

  return (inst: T | null) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};
