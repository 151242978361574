/**
 * Module dependencies.
 */

import { DefaultSeoProps } from 'next-seo';
import packageJson from 'package.json';

/**
 * Export `seoDefaultConfig`.
 */

export const seoDefaultConfig: DefaultSeoProps = {
  additionalMetaTags: [
    {
      content: packageJson.version,
      name: 'version'
    },
    {
      content: 'Uphold',
      name: 'author'
    }
  ],
  defaultTitle: 'Uphold',
  titleTemplate: '%s | Uphold'
};
