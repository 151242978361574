/**
 * Module dependencies.
 */

import { HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { useDetectOutsideClick } from 'src/hooks/use-detect-outside-click';
import Transition from 'react-transition-group/Transition';
import styled from 'styled-components';

/**
 * Export `DropdownProps` type.
 */

export type DropdownProps = HTMLAttributes<HTMLElement> & {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  onRequestClose?: () => void;
  position?: 'left' | 'right' | 'full';
  top?: number;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --dropdown-background-color: var(--color-neutral0);
  --dropdown-border-color: var(--color-neutral10);
  --dropdown-box-shadow: var(--box-shadow-lightXL);
  --dropdown-opacity: 0;
  --dropdown-transform: translateY(20px);

  &[data-transition='entering'],
  &[data-transition='entered'] {
    --dropdown-opacity: 1;
    --dropdown-transform: translateY(0);
  }

  [data-theme='dark'] & {
    --dropdown-background-color: var(--color-neutral95);
    --dropdown-border-color: var(--color-neutral70);
    --dropdown-box-shadow: var(--box-shadow-darkXL);
  }

  background-color: var(--dropdown-background-color);
  border: 1px solid var(--dropdown-border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--dropdown-box-shadow);
  opacity: var(--dropdown-opacity);
  overflow: hidden;
  position: absolute;
  top: calc(100% + var(--top, 16px));
  transform: var(--dropdown-transform);
  transition: var(--transition-default);
  transition-property: opacity, transform;
  z-index: 1;

  &[data-position='left'],
  &[data-position='full'] {
    left: 0;
  }

  &[data-position='right'],
  &[data-position='full'] {
    right: 0;
  }
`;

/**
 * Export `Dropdown` component.
 */

export const Dropdown = (props: DropdownProps) => {
  const { children, isOpen, onRequestClose, position = 'left', top = 16, ...rest } = props;
  const nodeRef = useRef<HTMLDivElement>(null);

  useDetectOutsideClick(nodeRef, onRequestClose);

  useEffect(() => {
    if (isOpen && nodeRef.current) {
      nodeRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Transition in={isOpen} mountOnEnter nodeRef={nodeRef} timeout={300} unmountOnExit>
      {transitionStatus => (
        <Wrapper
          {...rest}
          data-position={position}
          data-transition={transitionStatus}
          ref={nodeRef}
          style={{ ...rest.style, top: `calc(100% + ${top}px)` }}
          tabIndex={-1}
        >
          {children}
        </Wrapper>
      )}
    </Transition>
  );
};
