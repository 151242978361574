/**
 * Constants.
 */

const oneTrustConsentId = process.env.NEXT_PUBLIC_ONE_TRUST_CONSENT_ID;

/**
 * Export `oneTrust`.
 */

export function oneTrust() {
  const scriptExists = document.getElementById('oneTrustScript');

  if (scriptExists || !oneTrustConsentId) {
    return;
  }

  const oneTrustScript = document.createElement('script');

  oneTrustScript.id = 'oneTrustScript';
  oneTrustScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  oneTrustScript.async = true;
  oneTrustScript.setAttribute('charset', 'UTF-8');
  oneTrustScript.setAttribute('data-domain-script', oneTrustConsentId);

  document.head.appendChild(oneTrustScript);
}
