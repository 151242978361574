/**
 * Constants.
 */

const gtagId = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID;

/**
 * Export `googleAnalytics`.
 */

export function googleAnalytics() {
  const headScriptExists = document.getElementById('googleAnalyticsHeadScript');
  const bodyScriptExists = document.getElementById('googleAnalyticsBodyScript');

  if ((headScriptExists && bodyScriptExists) || !gtagId) {
    return;
  }

  const googleAnalyticsHeadScript = document.createElement('script');
  const googleAnalyticsBodyScript = document.createElement('script');

  googleAnalyticsHeadScript.id = 'googleAnalyticsHeadScript';
  googleAnalyticsHeadScript.async = true;
  googleAnalyticsHeadScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;

  googleAnalyticsBodyScript.id = 'googleAnalyticsBodyScript';
  googleAnalyticsBodyScript.async = true;
  googleAnalyticsBodyScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${gtagId}');
  `;

  document.body.appendChild(googleAnalyticsHeadScript);
  document.body.appendChild(googleAnalyticsBodyScript);
}
