/**
 * Module dependencies.
 */

import { Banner, TextSize } from 'src/api/entities/banners/types';
import { CardButton } from 'src/components/core/buttons/card-button';
import { CloseButton } from './close-button';
import { ComponentProps } from 'react';
import { DescriptionBannerProps } from './description-banner';
import { Image } from 'src/components/core/image';
import { RawHtml } from 'src/components/core/raw-html';
import { RichTextClean } from 'src/components/rich-text/clean';
import { Text } from 'src/components/core/text';
import { headingStyles } from 'src/components/core/layout/headings';
import { linkAnimatedStyles } from 'src/components/core/links';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDeviceSource } from 'src/hooks/use-device-source';
import arrowRightIcon from 'src/assets/svgs/24/arrow-right.svg';
import styled from 'styled-components';

/**
 * `ContentBannerProps` type.
 */

export type ContentBannerProps = Pick<Banner, 'asset' | 'assetMobile' | 'cta' | 'title'> &
  DescriptionBannerProps & {
    className?: string;
  };

/**
 * `titleVariantBySize` constant.
 */

const titleVariantBySize = {
  large: 'subtitle1',
  medium: 'subtitle2',
  small: 'paragraph1'
} as Record<TextSize, ComponentProps<typeof Text>['variant']>;

/**
 * `descriptionVariantBySize` constant.
 */

const descriptionVariantBySize = {
  large: 'paragraph1',
  medium: 'paragraph2',
  small: 'paragraph3'
} as Record<TextSize, ComponentProps<typeof Text>['variant']>;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  color: var(--color-neutral0);
  backdrop-filter: blur(6px);
  column-gap: 48px;
  display: grid;
  grid-template-areas: 'text';
  padding: 16px;
  position: relative;
  row-gap: 16px;

  &[data-is-dismissable='true'] {
    grid-template-areas: 'text dismissable';
    grid-template-columns: 1fr auto;
  }

  & > :not(img) {
    z-index: 1;
  }

  ${media.min.ms`
    padding: 14px 24px;

    &[data-is-dismissable='true'] {
      grid-template-areas: 'text . dismissable';
      grid-template-columns: 1fr repeat(2, auto);
    }
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-area: text;

  a {
    ${linkAnimatedStyles}
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ as: 'h6' })<{ variant: string }>`
  ${headingStyles}
  font-weight: 700;
`;

/**
 * `CloseIcon` styled component.
 */

const StyledCloseButton = styled(CloseButton)`
  grid-area: dismissable;

  ${media.max.ms`
    align-self: start;
  `}
`;

/**
 * Export `ContentBanner` component.
 */

export const ContentBanner = ({
  asset,
  assetMobile,
  backgroundColor,
  className,
  cta,
  description,
  dismissable,
  dismissableColor,
  onClose,
  textSize = 'medium',
  title
}: ContentBannerProps) => {
  const image = useDeviceSource([asset, assetMobile]);
  const isDesktop = useBreakpoint('md');

  return (
    <ContentWrapper
      className={className}
      data-is-dismissable={!!dismissable}
      data-theme={cta?.theme}
      {...(backgroundColor && { style: { backgroundColor } })}
    >
      {image?.url && <Image alt={image?.alt} fill src={image.url} style={{ objectFit: 'cover' }} />}

      <TextWrapper>
        {!!title?.length && (
          <RawHtml element={Title} elementProps={{ variant: titleVariantBySize[textSize] }}>
            {title}
          </RawHtml>
        )}

        <RichTextClean element={Text} elementProps={{ block: true, variant: descriptionVariantBySize[textSize] }}>
          {description}
        </RichTextClean>
      </TextWrapper>

      {cta?.href && (
        <CardButton
          href={cta?.href}
          icon={arrowRightIcon}
          size={textSize !== 'large' || !isDesktop ? 'small' : 'large'}
          target={cta?.target}
          variant={cta?.variant}
        >
          {cta?.label}
        </CardButton>
      )}

      {dismissable && <StyledCloseButton dismissableColor={dismissableColor} onClose={onClose} />}
    </ContentWrapper>
  );
};
