/**
 * Module dependencies.
 */

import { animationDelays, scrollAnimationsStyles } from './animations';
import { createGlobalStyle } from 'styled-components';
import { rootVariables } from './variables';
import styledNormalize from 'styled-normalize';

/**
 * Export `GlobalStyle` component.
 */

export const GlobalStyle = createGlobalStyle`
  ${rootVariables}
  ${styledNormalize}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  html{
    scroll-behavior: smooth;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: var(--color-neutral105);
    color: var(--color-text);
    font-family: var(--font-family-default);
    font-weight: 500;
    overflow-x: hidden;
    text-rendering: optimizelegibility;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  b,
  strong {
    font-weight: 700;
  }

  button,
  [role='button'] {
    appearance: none;
    cursor: pointer;
  }

  ol,
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  svg {
    display: block;
  }

  *:focus-visible {
    outline: 1px solid var(--color-primary);
    outline-offset: 1px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    appearance: none !important;
    background: none !important;
    background-color: transparent !important;
    transition: color 9999s ease-out, background-color 9999s ease-out;
    transition-delay: 9999s;
  }

  :root {
    ${scrollAnimationsStyles}
    ${animationDelays}
  }

  .grecaptcha-badge { 
    visibility: hidden;
  }
`;
