/**
 * Module dependencies.
 */

import { enableActivityTracking, trackPageView } from '@snowplow/browser-tracker';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 * Constants.
 */

const appId = process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID;
const collectorUrl = process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL;

/**
 * Export `useSnowplowTracking` hook.
 */

export function useSnowplowTracking(): void {
  const router = useRouter();

  const handleTrackPage = () => {
    if (!appId || !collectorUrl) {
      return;
    }

    trackPageView();

    enableActivityTracking({
      heartbeatDelay: 10,
      minimumVisitLength: 30
    });
  };

  useEffect(() => {
    handleTrackPage();
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleTrackPage);

    return () => {
      router.events.off('routeChangeComplete', handleTrackPage);
    };
  }, [router.events]);
}
