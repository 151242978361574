/**
 * Module dependencies.
 */

import { FontProperties, setFontStyle } from './utils/typography';
import { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';

/**
 * `createFontStyle` util.
 */

const createFontStyle = (properties: FontProperties) => {
  if (isEmpty(properties) || !properties) {
    throw new Error(`🚨 No font properties provided.`);
  }

  return css`
    ${setFontStyle(properties)}

    margin: 0;
  `;
};

/**
 * `heading1` styles.
 */

const heading1 = {
  fontSize: 72,
  fontSizeMin: 40,
  letterSpacing: '-0.03em',
  lineHeight: 79,
  lineHeightMin: 44
} as FontProperties;

/**
 * `heading2` styles.
 */

const heading2 = {
  fontSize: 56,
  fontSizeMin: 36,
  letterSpacing: '-0.03em',
  lineHeight: 62,
  lineHeightMin: 40
} as FontProperties;

/**
 * `heading3` styles.
 */

const heading3 = {
  fontSize: 40,
  fontSizeMin: 32,
  letterSpacing: '-0.03em',
  lineHeight: 44,
  lineHeightMin: 35
} as FontProperties;

/**
 * `heading4` styles.
 */

const heading4 = {
  fontSize: 36,
  fontSizeMin: 28,
  letterSpacing: '-0.03em',
  lineHeight: 40,
  lineHeightMin: 30
} as FontProperties;

/**
 * `subtitle1` styles.
 */

const subtitle1 = {
  fontSize: 32,
  fontSizeMin: 24,
  letterSpacing: '-0.02em',
  lineHeight: 38,
  lineHeightMin: 28
} as FontProperties;

/**
 * `subtitle2` styles.
 */

const subtitle2 = {
  fontSize: 24,
  fontSizeMin: 20,
  letterSpacing: '-0.02em',
  lineHeight: 32,
  lineHeightMin: 26
} as FontProperties;

/**
 * `paragraph1` styles.
 */

const paragraph1 = {
  fontSize: 20,
  fontSizeMin: 18,
  letterSpacing: '-0.02em',
  lineHeight: 26,
  lineHeightMin: 24
} as FontProperties;

/**
 * `paragraph2` styles.
 */

const paragraph2 = {
  fontSize: 16,
  fontSizeMin: 16,
  letterSpacing: '-0.02em',
  lineHeight: 21,
  lineHeightMin: 19
} as FontProperties;

/**
 * `paragraph3` styles.
 */

const paragraph3 = {
  fontSize: 14,
  fontSizeMin: 14,
  letterSpacing: '-0.02em',
  lineHeight: 17,
  lineHeightMin: 18
} as FontProperties;

/**
 * `small` styles.
 */

const small = {
  fontSize: 12,
  letterSpacing: '-0.02em',
  lineHeight: 14
} as FontProperties;

/**
 * Variants themes.
 */

export const textStyles = {
  heading1: createFontStyle(heading1),
  heading2: createFontStyle(heading2),
  heading3: createFontStyle(heading3),
  heading4: createFontStyle(heading4),
  label1: createFontStyle(paragraph1),
  label2: createFontStyle(paragraph2),
  paragraph1: createFontStyle(paragraph1),
  paragraph2: createFontStyle(paragraph2),
  paragraph3: createFontStyle(paragraph3),
  small: createFontStyle(small),
  subtitle1: createFontStyle(subtitle1),
  subtitle2: createFontStyle(subtitle2)
} as const;
