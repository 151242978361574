/**
 * Module dependencies.
 */

import { AnimationStatus, PageTransition } from 'src/components/layout/page-transition';
import { Banner, BannerLayout } from 'src/api/entities/banners/types';
import { BottomBanner } from 'src/components/banners/bottom';
import { Footer } from './footer';
import { FooterFragment } from 'src/api/entities/footer/types';
import { GetStartedModal } from './modal/get-started';
import { Navbar } from './navbar';
import { NavbarFragment } from 'src/api/entities/navbar/types';
import { PageTopBanner } from 'src/components/banners/page-top';
import { PropsWithChildren, useMemo, useState } from 'react';
import { TopBanners } from 'src/components/banners/top';
import { useSettings } from 'src/context/settings';

/**
 * `Props` type.
 */

type Props = PropsWithChildren<{
  banners: Banner[] | null;
  pageFooter?: FooterFragment;
  pageNavbar?: NavbarFragment;
}>;

/**
 * `filterBannersByLayout` util.
 */

function filterBannersByLayout(
  banners: Banner[] | null,
  options: {
    layout: BannerLayout;
    userCountry: string | null;
  }
) {
  const { layout, userCountry } = options;

  return banners?.find(banner => {
    if (banner?.whitelistedCountries) {
      return (
        banner.whitelistedCountries.includes(userCountry || '') &&
        banner.layout === layout
      );
    }

    return banner?.layout === layout;
  });
}

/**
 * Export `Layout` component.
 */

export function Layout({ banners, children, pageFooter, pageNavbar }: Props) {
  const { userCountry } = useSettings();
  const { bannersBottom, bannersNavbarBottom, bannersPageTop, bannersTop } = useMemo(
    () => ({
      bannersBottom: filterBannersByLayout(banners, { layout: 'bottom', userCountry }),
      bannersNavbarBottom: filterBannersByLayout(banners, { layout: 'navbar-bottom', userCountry }),
      bannersPageTop: filterBannersByLayout(banners, { layout: 'page-top', userCountry }),
      bannersTop: filterBannersByLayout(banners, { layout: 'top', userCountry})
    }),
    [banners, userCountry]
  );

  const [pageAnimationStatus, setPageAnimationStatus] = useState<AnimationStatus>('idle');

  return (
    <>
      <Navbar banner={bannersNavbarBottom} pageNavbar={pageNavbar} pageTransitionStatus={pageAnimationStatus} />

      <GetStartedModal />

      <PageTransition animationStatus={pageAnimationStatus} setAnimationStatus={setPageAnimationStatus}>
        <TopBanners banner={bannersTop} />

        <PageTopBanner banner={bannersPageTop} />

        <BottomBanner banner={bannersBottom} />

        <main>{children}</main>

        <Footer pageFooter={pageFooter} />
      </PageTransition>
    </>
  );
}
