/**
 * Constants.
 */

const adRollAdvId = process.env.NEXT_PUBLIC_ADROLL_ADV_ID;
const adRollPixId = process.env.NEXT_PUBLIC_ADROLL_PIX_ID;

/**
 * Export `adRoll`.
 */

export function adRoll() {
  const scriptExists = document.getElementById('adRollScript');

  if (scriptExists || !adRollAdvId || !adRollPixId) {
    return;
  }

  const adRollScript = document.createElement('script');

  adRollScript.id = 'adRollScript';
  adRollScript.async = true;
  adRollScript.innerHTML = `
    adroll_adv_id = "${adRollAdvId}";
    adroll_pix_id = "${adRollPixId}";
    adroll_version = "2.0";
    (function(w, d, e, o, a) {
      w.__adroll_loaded = true;
      w.adroll = w.adroll || [];
      w.adroll.f = ['setProperties', 'identify', 'track'];
      var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js";
      for (a = 0; a < w.adroll.f.length; a++) {
        w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
          return function() {
            w.adroll.push([n, arguments]);
          };
        })(w.adroll.f[a]);
      }
      e = d.createElement('script');
      o = d.getElementsByTagName('script')[0];
      e.async = 1;
      e.src = roundtripUrl;
      o.parentNode.insertBefore(e, o);
    })(window, document);
    adroll.track("pageView");
  `;

  document.head.appendChild(adRollScript);
}
