/*
 * Config constants.
 */

const lightColor = '#8494a533';
const darkColor = '#11151c33';
const spreads = [1, 2, 4, 8];

/*
 * `getElevation` util.
 */

const getElevation = (spread: number, color: string) => `0 ${spread}px ${spread * 2}px 0 ${color}`;

/*
 * Export `elevations`.
 */

export const elevations = {
  darkL: getElevation(spreads[2], darkColor),
  darkM: getElevation(spreads[1], darkColor),
  darkS: getElevation(spreads[0], darkColor),
  darkXL: getElevation(spreads[3], darkColor),
  lightL: getElevation(spreads[2], lightColor),
  lightM: getElevation(spreads[1], lightColor),
  lightS: getElevation(spreads[0], lightColor),
  lightXL: getElevation(spreads[3], lightColor)
};
