/**
 * Module dependencies.
 */

import { createPortal } from 'react-dom';

/**
 * Export `createReactPortal`.
 */

export function createReactPortal(children: any, elementId: string) {
  if (!(typeof window !== 'undefined' && window.document && window.document.createElement)) {
    return null;
  }

  try {
    return createPortal(children, document.getElementById(elementId) as HTMLElement);
  } catch {
    // Error catch for storybook.
    const modalRoot = document.createElement('div');

    modalRoot.setAttribute('id', 'modal-root');
    document.body.appendChild(modalRoot);
  }
}
