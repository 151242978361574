/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { HelpText, Label } from 'src/components/core/forms/styles';
import { RawHtml } from 'src/components/core/raw-html';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> & {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  error?: string;
  helpText?: string;
  id: string;
  label?: string;
  labelHasHtml?: boolean;
};

/**
 * Export `FormGroupProps` type.
 */

export type FormGroupProps = Omit<Props, 'children' | 'size'> & {
  size?: 'default' | 'medium' | 'small';
  variant?: 'default' | 'filled' | 'rounded' | 'ghost';
};

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled.div`
  --input-background-color: var(--color-neutral0);
  --input-border-color: var(--color-neutral10);
  --input-color: var(--color-neutral95);
  --input-focus-border-color: var(--color-neutral70);
  --input-helptext-color: var(--color-neutral70);
  --input-hover-border-color: var(--color-neutral30);
  --input-label-color: var(--color-neutral70);
  --input-placeholder-color: var(--color-neutral40);
  --input-shadow: var(--box-shadow-lightS);

  :focus-within {
    --input-placeholder-color: var(--color-neutral70);
  }

  &[data-error='true'] {
    --input-border-color: var(--color-critical60);
    --input-background-color: var(--color-white);
    --input-helptext-color: var(--color-critical70);
    --input-label-color: var(--color-critical70);
    --input-placeholder-color: var(--color-neutral50);

    &[data-value='true'] {
      --input-background-color: var(--color-neutral0);
      --input-border-color: var(--color-critical60);
      --input-focus-border-color: var(--color-critical60);
      --input-hover-border-color: var(--color-critical60);
    }
  }

  &[data-disabled='true'] {
    --input-background-color: var(--color-neutral05);
    --input-border-color: var(--color-neutral05);
    --input-color: var(--color-neutral30);
    --input-helptext-color: var(--color-neutral10);
    --input-label-color: var(--color-neutral30);
    --input-placeholder-color: var(--color-neutral10);

    cursor: not-allowed;
    pointer-events: none;
  }

  [data-theme='dark'] & {
    --input-background-color: var(--color-neutral80);
    --input-border-color: var(--color-neutral80);
    --input-color: var(--color-white);
    --input-focus-border-color: var(--color-neutral30);
    --input-helptext-color: var(--color-neutral40);
    --input-hover-border-color: var(--color-neutral60);
    --input-label-color: var(--color-white);
    --input-placeholder-color: var(--color-neutral40);
    --input-shadow: var(--box-shadow-darkS);

    &[data-error='true'] {
      --input-border-color: var(--color-critical60);
      --input-helptext-color: var(--color-critical50);
      --input-label-color: var(--color-critical50);

      &[data-value='true'] {
        --input-background-color: var(--color-neutral80);
        --input-focus-border-color: var(--color-critical60);
        --input-hover-border-color: var(--color-critical60);
      }
    }

    &[data-disabled='true'] {
      --input-background-color: var(--color-neutral80);
      --input-border-color: var(--color-neutral80);
      --input-color: var(--color-neutral70);
      --input-helptext-color: var(--color-neutral70);
      --input-label-color: var(--color-neutral70);
      --input-placeholder-color: var(--color-neutral60);
    }
  }

  --labels-gap: 24px;

  [data-tight-labels='true'] & {
    --labels-gap: 16px;
  }

  &:not([data-empty='true']) {
    display: grid;
    grid-row-gap: 8px;
    grid-template-areas: '. label .' 'input input input' '. helptext .';
    grid-template-columns: var(--labels-gap) 1fr var(--labels-gap);
    width: 100%;
  }
`;

/**
 * Export `FormGroup` component.
 */

export const FormGroup = (props: Props) => {
  const { children, className, disabled, error, helpText, id, label, labelHasHtml, value, ...rest } = props;

  return (
    <Wrapper
      {...rest}
      className={className}
      data-disabled={disabled}
      data-empty={!label && !helpText && !error}
      data-error={!!error}
      data-value={!!value}
    >
      {label &&
        (labelHasHtml ? (
          // @ts-expect-error - Pass `for` to label element.
          <RawHtml element={Label} htmlFor={id} style={{ gridArea: 'label' }}>
            {label}
          </RawHtml>
        ) : (
          <Label htmlFor={id} style={{ gridArea: 'label' }}>
            {label}
          </Label>
        ))}

      <div style={{ gridArea: 'input' }}>{children}</div>

      {(error || helpText) && <HelpText style={{ gridArea: 'helptext' }}>{error || helpText}</HelpText>}
    </Wrapper>
  );
};

/**
 * `FormGroup` display name.
 */

FormGroup.displayName = 'FormGroup';
