/**
 * Module dependencies.
 */

import { AnimationStatus } from 'src/components/layout/page-transition';
import { Button } from 'src/components/core/buttons/button';
import { DropdownMenu } from './dropdown-menu';
import { Link } from 'src/components/core/links';
import { MenuIcon } from './hamburger-button';
import { NavbarBottomBanner, NavbarBottomBannerProps } from 'src/components/banners/navbar-bottom';
import { NavbarFragment } from 'src/api/entities/navbar/types';
import { ProgressBar } from 'src/components/layout/page-transition/progress-bar';
import { Svg } from 'src/components/core/svg';
import { appModalId } from 'src/hooks/use-uphold-app-url';
import { colors } from 'src/styles/colors';
import { fadeIn } from 'src/styles/keyframes';
import { getLocale, isLocale } from 'src/core/utils/locale';
import { media } from 'src/styles/media';
import { seoDefaultConfig } from 'src/core/constants/seo-default-config';
import { transparentize } from 'src/styles/utils/colors';
import { urlPathToParts } from 'src/core/utils/url';
import { useBodyScroll } from 'src/hooks/use-body-scroll';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDeviceSource } from 'src/hooks/use-device-source';
import { useEffect, useMemo, useState } from 'react';
import { useElementDimensions } from 'src/hooks/use-element-dimensions';
import { useKeyPress } from 'src/hooks/use-key-press';
import { useRouter } from 'next/router';
import { useSettings } from 'src/context/settings';
import arrowRightIcon from 'src/assets/svgs/24/arrow-right.svg';
import logoSvg from 'src/assets/svgs/logo.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Constants.
 */

const desktopNavLogoWidth = 120;
const navbarHorizontalPadding = 16;

/**
 * `Props` type.
 */

type Props = NavbarBottomBannerProps & {
  pageNavbar?: NavbarFragment;
  pageTransitionStatus: AnimationStatus;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --navbar-actions-gap: 16px;
  --navbar-border-radius: 16px;
  --navbar-logo-width: 88px;
  --navbar-max-width: calc(100vw - var(--gutter-navbar-x) * 2);
  --navbar-transition-default: 350ms cubic-bezier(0.4, 0, 0.2, 1);

  border-radius: var(--navbar-border-radius);
  color: ${colors.neutral0};
  font-size: 20px;
  font-weight: 500;
  inset: calc(var(--gutter-navbar-y) + var(--top-banner-height)) var(--gutter-navbar-x) auto;
  max-width: var(--navbar-max-width);
  position: fixed;
  z-index: var(--z-index-navbar);

  &[data-mobile-version='false'] {
    --navbar-actions-gap: var(--gutter-navbar-x);
  }

  ${media.min.ms`
    --navbar-max-width: calc(var(--container-max-width) - var(--gutter-navbar-x) * 2);
    margin: 0 auto;
  `}

  ${media.min.md`
    --navbar-logo-width: ${desktopNavLogoWidth}px;
  `}

  ${media.min.xl`
    inset: calc(16px + var(--top-banner-height)) 0 auto 0;
  `}
`;

/**
 * `NavWrapper` styled component.
 */

const NavWrapper = styled.div`
  border-radius: var(--navbar-border-radius);
  inset: 0 0 auto;
  overflow: hidden;
  position: absolute;
`;

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  align-items: center;
  backdrop-filter: blur(16px);
  background-color: ${transparentize('neutral95', 0.85)};
  display: flex;
  height: var(--navbar-height);
  justify-content: space-between;
  overflow: hidden;
  padding: 8px ${navbarHorizontalPadding}px;
  position: relative;
  transition: background-color var(--navbar-transition-default);

  &[data-is-expanded='true'] {
    background-color: var(--color-neutral105);
  }
`;

/**
 * `HorizontalLinksList` styled component.
 */

const HorizontalLinksList = styled.ul`
  display: none;

  &[data-mobile-version='false'] {
    animation: ${fadeIn} 350ms;
    display: flex;
    gap: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }

  &:not(:focus-within) [data-highlight='true'] {
    color: ${colors.primaryForDark};
  }
`;

/**
 * `VerticalLinksList` styled component.
 */

const VerticalLinksList = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 32px;
  gap: 8px;
  justify-content: center;
  max-width: calc(var(--navbar-max-width) - var(--gutter-navbar-x) * 2);
  width: 100%;

  li {
    display: flex;
  }

  &:not(:focus-within) [data-highlight='true'] {
    color: ${colors.primaryForDark};
  }
`;

/**
 * `DropdownActions` styled component.
 */

const DropdownActions = styled.div`
  display: grid;
  grid-gap: 8px;
  max-width: calc(var(--navbar-max-width) - var(--gutter-navbar-x) * 2);
  padding-top: 48px;
  width: 100%;

  ${media.min.sm`
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  `}
`;

/**
 * `NavLink` styled component.
 */

const NavLink = styled(Link)`
  &[data-bold] {
    font-weight: 700;
  }

  :focus,
  :hover {
    color: ${colors.primaryForDark};
  }
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  align-items: center;
  display: flex;
  gap: var(--navbar-actions-gap);

  &[data-mobile-version='true'] {
    padding-right: calc(24px + var(--navbar-actions-gap));
  }

  &[data-initial-render='false'] {
    animation: ${fadeIn} 350ms;
  }

  &[data-initial-render='true'] {
    display: none;
  }
`;

/**
 * `NavbarDesktopLink` styled component.
 */

const NavbarDesktopLink = styled(NavLink)`
  white-space: nowrap;

  &[data-mobile-version='true'] {
    display: none;
  }
`;

/**
 * `MenuButton` styled component.
 */

const MenuButton = styled(MenuIcon)`
  position: absolute;
  right: ${navbarHorizontalPadding}px;
  top: calc((var(--navbar-height) - 24px) * 0.5);
  z-index: 1;

  &[data-initial-render='true'] {
    display: none;
  }

  &[data-initial-render='false'] {
    animation: ${fadeIn} 350ms;
  }
`;

/**
 * `getBaseSlug` function.
 */

const getBaseSlug = (url: string | undefined) => {
  if (!url) {
    return null;
  }

  const parts = urlPathToParts(url);

  if (isLocale(parts[0])) {
    return parts[1];
  }

  return parts[0];
};

/**
 * `Navbar` component.
 */

export function Navbar({ banner, pageNavbar, pageTransitionStatus }: Props) {
  const { locale, regionSettings } = useSettings();
  const { navbar: settingsNavbar } = regionSettings ?? {};
  const navbar = pageNavbar ?? settingsNavbar;
  const [navRef, { width: navWidth }] = useElementDimensions<HTMLDivElement>();
  const [actionsRef, { width: actionsWidth }] = useElementDimensions<HTMLDivElement>();
  const [listRef, { width: listWidth }] = useElementDimensions<HTMLUListElement>();
  const [maxActionsWidth, setMaxActionsWidth] = useState(0);
  const [maxListWidth, setMaxListWidth] = useState(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isDesktop = useBreakpoint('md');
  const isNavbarMobile = useMemo(() => {
    const isOverflowing = maxListWidth + maxActionsWidth + desktopNavLogoWidth > navWidth - navbarHorizontalPadding * 2;

    return !isDesktop || isOverflowing;
  }, [isDesktop, maxActionsWidth, maxListWidth, navWidth]);

  const rootLink = useMemo(() => `/${getLocale(locale.regionCode, locale.languageCode)}`, [locale]);
  const router = useRouter();
  const hasDropdownMenu = useMemo(
    () => navbar?.links?.length > 0 && isNavbarMobile,
    [isNavbarMobile, navbar?.links?.length]
  );

  const renderLinksListComponents = useMemo(
    () =>
      navbar?.links?.map(({ href, label }) => (
        <li key={label} onClick={() => setIsOpen(false)}>
          <NavLink data-highlight={getBaseSlug(router.asPath) === getBaseSlug(href)} href={href}>
            {label}
          </NavLink>
        </li>
      )),
    [navbar?.links, router.asPath]
  );

  useBodyScroll({ off: isOpen });

  useKeyPress('Escape', () => {
    if (isNavbarMobile) {
      setIsOpen(false);
    }
  });

  const hasModal = useMemo(() => navbar.ctaPrimaryDesktop?.href?.slice(1) === appModalId, [navbar]);
  const primaryButton = useDeviceSource([navbar.ctaPrimaryDesktop, navbar.ctaPrimaryMobile], 'md');

  useEffect(() => {
    // Force close the menu when the breakpoint limit changes.
    if (!isNavbarMobile) {
      setIsOpen(false);

      return;
    }
  }, [isNavbarMobile]);

  useEffect(() => {
    if (listWidth !== 0) {
      setMaxListWidth(listWidth);
    }
  }, [listWidth]);

  useEffect(() => {
    if (actionsWidth > maxActionsWidth && actionsWidth !== 0) {
      setMaxActionsWidth(actionsWidth);
    }
  }, [actionsWidth, maxActionsWidth]);

  useEffect(() => {
    const handleRouteChange = () => {
      (document.activeElement as HTMLElement)?.blur();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  if (!navbar) {
    return null;
  }

  return (
    <Wrapper data-mobile-version={isNavbarMobile} data-theme={'dark'}>
      <NavWrapper data-is-expanded={isOpen && isNavbarMobile} ref={navRef}>
        <Nav>
          <Link href={rootLink} style={{ lineHeight: 0 }} title={seoDefaultConfig.defaultTitle}>
            <Svg icon={logoSvg} size={'var(--navbar-logo-width)'} />
          </Link>

          <HorizontalLinksList aria-hidden={isNavbarMobile} data-mobile-version={isNavbarMobile} ref={listRef}>
            {renderLinksListComponents}
          </HorizontalLinksList>

          <Actions data-initial-render={isDesktop === undefined} data-mobile-version={hasDropdownMenu} ref={actionsRef}>
            {navbar.ctaSecondary && (
              <NavbarDesktopLink data-mobile-version={isNavbarMobile} {...omit(navbar.ctaSecondary, 'label')}>
                {navbar.ctaSecondary.label}
              </NavbarDesktopLink>
            )}

            {primaryButton && (
              <Button {...omit(primaryButton, 'label')} {...(!hasModal && { id: appModalId })} icon={arrowRightIcon}>
                {primaryButton.label}
              </Button>
            )}
          </Actions>

          <ProgressBar status={pageTransitionStatus} />
        </Nav>

        {hasDropdownMenu && (
          <MenuButton
            aria-expanded={isOpen}
            aria-haspopup={'listbox'}
            aria-label={'navigation menu'}
            data-initial-render={isDesktop === undefined}
            onClick={() => {
              setIsOpen(open => !open);
            }}
          >
            <div />
          </MenuButton>
        )}

        <NavbarBottomBanner banner={banner} />
      </NavWrapper>

      {hasDropdownMenu && (
        <DropdownMenu isOpen={isOpen}>
          <VerticalLinksList>{renderLinksListComponents}</VerticalLinksList>

          <DropdownActions>
            {navbar.ctaSecondary && (
              <Button
                {...omit(navbar.ctaSecondary, 'label')}
                onClick={() => setIsOpen(false)}
                stretch
                variant={'secondary'}
              >
                {navbar.ctaSecondary.label}
              </Button>
            )}

            {navbar.ctaPrimaryMobile && (
              <Button {...omit(navbar.ctaPrimaryMobile, 'label')} onClick={() => setIsOpen(false)} stretch>
                {navbar.ctaPrimaryMobile.label}
              </Button>
            )}
          </DropdownActions>
        </DropdownMenu>
      )}
    </Wrapper>
  );
}

/**
 * `Navbar` display name.
 */

Navbar.displayName = 'Navbar';
