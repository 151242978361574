/**
 * Module dependencies.
 */

import { GlobalSettings } from 'src/api/entities/settings/global/types';
import { PropsWithChildren, createContext, useCallback, useContext } from 'react';
import get from 'lodash/get';

/**
 * `I18nContextProps` type.
 */

type I18nContextProps = {
  language: string;
  t: (key: string) => string;
};

/**
 * `I18nContext` context.
 */

const I18nContext = createContext<I18nContextProps>(null!);

/**
 * Export `useTranslate` hook.
 */

export const useTranslate = () => useContext(I18nContext);

/**
 * `Props` type.
 */

type Props = Omit<I18nContextProps, 't'> &
  PropsWithChildren<{
    i18n: GlobalSettings['i18n'] | undefined;
  }>;

/**
 * Export `I18nProvider` provider.
 */

export const I18nProvider = ({ children, i18n, ...rest }: Props) => {
  const t = useCallback((key: string) => get(i18n, key) ?? '', [i18n]);

  return <I18nContext.Provider value={{ ...rest, t }}>{children}</I18nContext.Provider>;
};
