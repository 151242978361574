/**
 * Export `regexes` constant.
 */

export const regexes = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  emailLink: /mailto:([^?]*)/,
  locale: /^[a-z]{2}(-[a-z]{2})?$/,
  serverVariable: /{{([A-Z][A-Z0-9_]*?)}}/g,
  telLink: /^(tel:)(\+[0-9]+)/,
  url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/
};
