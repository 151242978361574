/**
 * Module dependencies.
 */

import { useCallback } from 'react';
import { useSettings } from 'src/context/settings';

/**
 * Export `useInternalUrl` hook.
 */

export function useInternalUrl() {
  const { slugs } = useSettings();

  const handleIsInternalUrl = useCallback(
    (url: string) => {
      return slugs.has(`/${url}`.replace(/\/\//g, '/').replace(/\/$/, ''));
    },
    [slugs]
  );

  return handleIsInternalUrl;
}
