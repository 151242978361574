/**
 * Module dependencies.
 */

import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { useBodyScroll } from 'src/hooks/use-body-scroll';
import { useRouter } from 'next/router';
import styled, { keyframes } from 'styled-components';

/**
 * Constant.
 */

const animationDurationMs = 300;

/**
 * Export `AnimationStatus` type.
 */

export type AnimationStatus = 'start' | 'end' | 'idle';

/**
 * Keyframes for blur and fade-out.
 */

const blurFadeOut = keyframes`
  from {
    filter: blur(0);
  }

  to {
    filter: blur(5px);
  }
`;

/**
 * Keyframes for blur and fade-in.
 */

const blurFadeIn = keyframes`
  from {
    filter: blur(5px);
  }

  to {
    filter: blur(0);
  }
`;

/**
 * `Props` type.
 */

type Props = {
  animationStatus: AnimationStatus;
  children: ReactNode;
  setAnimationStatus: Dispatch<SetStateAction<AnimationStatus>>;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  pointer-events: initial;
  position: relative;
  z-index: var(--z-index-page-transition);

  &[data-animation='start'] {
    animation: ${blurFadeOut} ${animationDurationMs}ms linear forwards;
    pointer-events: none;
  }

  &[data-animation='end'] {
    animation: ${blurFadeIn} ${animationDurationMs * 2}ms linear forwards;
  }
`;

/**
 * `PageTransition` component.
 */

export function PageTransition(props: Props) {
  const { animationStatus, children, setAnimationStatus } = props;
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setAnimationStatus('start');
    };

    const handleRouteChangeComplete = () => {
      setAnimationStatus('end');

      setTimeout(() => {
        setAnimationStatus('idle');
      }, animationDurationMs * 2);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [animationStatus, router.events, setAnimationStatus]);

  useBodyScroll({ off: animationStatus === 'start' });

  return <Wrapper data-animation={animationStatus}>{children}</Wrapper>;
}
