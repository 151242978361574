/**
 * Module dependencies.
 */

import { HTMLProps } from 'react';
import { setLinkProps } from 'src/core/utils/links';
import styled, { css } from 'styled-components';

/**
 * `LinkProps` type.
 */

type LinkProps = HTMLProps<HTMLAnchorElement>;

/**
 * Export `linkBaseStyles`.
 */

export const linkBaseStyles = css`
  appearance: none;
  transition: var(--transition-default);
  transition-property: color, opacity;

  &:disabled {
    pointer-events: none;
  }

  &[href]:hover {
    opacity: 0.8;
  }
`;

/**
 * `linkAnimatedOnlyStyles`.
 */

const linkAnimatedOnlyStyles = css`
  background: linear-gradient(currentcolor, currentcolor);
  background-position: 0 calc(100% - 2px);
  background-repeat: no-repeat;
  background-size: 0% 1px;
  text-decoration: none;
  transition-property: background-size, color;

  &:hover {
    background-size: 100% 1px;
    opacity: 1 !important;
  }
`;

/**
 * Export `linkAnimatedStyles`.
 */

export const linkAnimatedStyles = css`
  ${linkBaseStyles}
  ${linkAnimatedOnlyStyles}
`;

/**
 * `Link` styled component.
 */

export const Link = styled.a.attrs<LinkProps>(setLinkProps)`
  ${linkBaseStyles}

  &[data-underline-animated='true'][href] {
    ${linkAnimatedOnlyStyles}
  }
`;
