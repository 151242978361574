/**
 * Module dependencies.
 */

import styled from 'styled-components';

/**
 * Export `ContainerProps` type.
 */

export type ContainerProps = {
  size?: 'default' | 'fluid' | 'narrow';
};

/**
 * Export `Container` styled component.
 */

export const Container = styled.div
  .attrs(({ size }: ContainerProps) => ({ 'data-size': size || 'default' }))
  .withConfig({ shouldForwardProp: prop => !['size'].includes(prop) })<ContainerProps>`
  &[data-size='default'] {
    --container-width: var(--container-max-width);
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }

  &[data-size='fluid'] {
    --container-width: var(--container-fluid-max-width);
  }

  &[data-size='narrow'] {
    --container-width: var(--container-narrow-max-width);
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }

  margin: 0 auto;
  max-width: min(100vw, var(--container-width));
  width: 100%;
`;
