/**
 * Module dependencies.
 */

import { GlobalSettings } from 'src/api/entities/settings/global/types';
import { getLocale } from './locale';
import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';

/**
 * Export `convertKeysToCamelCase` util.
 */

export function convertKeysToCamelCase<T extends Record<string, any>>(obj: T): ConvertKeysToCamelCase<T> {
  if (!obj) {
    return obj;
  }

  const newObj = {} as Record<string, any>;

  Object.keys(obj)
    .sort()
    .forEach(key => {
      const newKey = camelCase(key);
      const value = obj[key];

      if (isPlainObject(value)) {
        newObj[newKey] = convertKeysToCamelCase(value);
      } else if (Array.isArray(value)) {
        newObj[newKey] = value.map(item => (isPlainObject(item) ? convertKeysToCamelCase(item) : item));
      } else {
        newObj[newKey] = value;
      }
    });

  return newObj as ConvertKeysToCamelCase<T>;
}

/**
 * Export `normalizeLocaleSelect` util.
 */

export function normalizeLocaleSelect(regions: GlobalSettings['regions'] | undefined) {
  if (!regions) {
    return [];
  }

  return regions
    .flatMap(({ code, languages, translations }) =>
      languages.map(({ languagesCode }) => {
        const regionName = translations.find(translation => translation.languagesCode.code === languagesCode?.code)?.name;
        const region = regionName ? ` (${regionName})` : '';

        return {
          label: `${languagesCode?.name}${region}`,
          value: getLocale(code, languagesCode?.code)
        }
      })
    )
    .filter(({ label, value }) => !`${value}${label}`.includes('undefined'))
    .sort((first, second) => first.value.localeCompare(second.value));
}
