/**
 * Module dependencies.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { Banner } from 'src/api/entities/banners/types';
import { DescriptionBanner } from './shared/description-banner';
import { useEffect } from 'react';
import { useElementDimensions } from 'src/hooks/use-element-dimensions';
import { useIsClient } from 'src/context/client';
import { useSessionStorage } from 'src/hooks/use-session-storage';

/**
 * Export `NavbarBottomBannerProps` type.
 */

export type NavbarBottomBannerProps = {
  banner?: Banner;
};

/**
 * `ContentProps` type.
 */

type ContentProps = {
  banner: Banner;
  onClose: () => void;
};

/**
 * `NavbarBottomBannerContent` component.
 */

const NavbarBottomBannerContent = ({ banner, onClose }: ContentProps) => {
  const [ref, { height }] = useElementDimensions<HTMLDivElement>();

  useEffect(() => {
    if (height) {
      document.documentElement.style.setProperty('--nav-bottom-banner-height', `${height}px`);
    }

    return () => {
      document.documentElement.style.setProperty('--nav-bottom-banner-height', '0px');
    };
  }, [height]);

  return (
    <motion.div
      {...(banner.dismissable && { initial: { height: 0 } })}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      ref={ref}
    >
      <DescriptionBanner {...banner} onClose={onClose} />
    </motion.div>
  );
};
/**
 * Export `NavbarBottomBanner` component.
 */

export const NavbarBottomBanner = ({ banner }: NavbarBottomBannerProps) => {
  const isClient = useIsClient();
  const [hideBanner, setHideBanner] = useSessionStorage(`banner-${banner?.id}`);

  return (
    <AnimatePresence>
      {banner && (!banner.dismissable || (isClient && !hideBanner)) && (
        <motion.div
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          {...(banner.dismissable && { initial: { opacity: 0 } })}
        >
          <NavbarBottomBannerContent banner={banner} onClose={() => setHideBanner(true)} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
