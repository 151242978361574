/**
 * Module dependencies.
 */

import { AnimatePresence, motion, useIsomorphicLayoutEffect } from 'framer-motion';
import { Banner } from 'src/api/entities/banners/types';
import { Container } from 'src/components/core/layout/container';
import { ContentBanner } from './shared/content-banner';
import { media } from 'src/styles/media';
import { useEffect, useMemo } from 'react';
import { useElementDimensions } from 'src/hooks/use-element-dimensions';
import { useIsClient } from 'src/context/client';
import { useSessionStorage } from 'src/hooks/use-session-storage';
import styled from 'styled-components';

/**
 * Props type.
 */

type Props = {
  banner: Banner;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(motion.div)`
  bottom: 0;
  min-width: 100%;
  position: fixed;
  z-index: var(--z-index-banner);

  ${media.min.ms`
    bottom: 16px;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  ${media.max.ms`
    padding: 0 !important;
  `}
`;

/**
 * `StyledContentBanner` styled component.
 */

const StyledContentBanner = styled(ContentBanner)`
  border-radius: 16px 16px 0 0;
  overflow: hidden;

  ${media.min.ms`
    border-radius: 16px;
    margin: 0 var(--gutter-cards);
  `}
`;

/**
 * `BottomBannerContent` component.
 */

const BottomBannerContent = ({ banner, onClose }: Props & { onClose: () => void }) => {
  const [ref, { height }] = useElementDimensions<HTMLDivElement>();

  useIsomorphicLayoutEffect(() => {
    if (height) {
      document.documentElement.style.setProperty('--bottom-banner-height', `${height}px`);
    }

    return () => {
      document.documentElement.style.setProperty('--bottom-banner-height', '0px');
    };
  }, [height]);

  return (
    <Wrapper
      {...(banner.dismissable && { initial: { opacity: 0 } })}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, y: '50%' }}
      ref={ref}
    >
      <StyledContainer>
        <StyledContentBanner {...banner} onClose={onClose} />
      </StyledContainer>
    </Wrapper>
  );
};

/**
 * Export `BottomBanner` component.
 */

export const BottomBanner = ({ banner }: Partial<Props>) => {
  const isClient = useIsClient();
  const [hideBanner, setHideBanner] = useSessionStorage(`banner-${banner?.id}`);
  const showBanner = useMemo(
    () => banner && (!banner.dismissable || (isClient && !hideBanner)),
    [banner, hideBanner, isClient]
  );

  useEffect(() => {
    if (!showBanner) {
      document.documentElement.style.setProperty('--bottom-banner-height', '0px');
    }
  }, [showBanner]);

  return (
    <AnimatePresence>
      {showBanner && <BottomBannerContent banner={banner as Banner} onClose={() => setHideBanner(true)} />}
    </AnimatePresence>
  );
};
