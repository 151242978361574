/**
 * Module dependencies.
 */

import { keyframes } from 'styled-components';

/**
 * Export `fadeIn` keyframes.
 */

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

/**
 * Export `fadeInUp` keyframes.
 */

export const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(32px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

/**
 * Export `fadeOut` keyframes.
 */

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

/**
 * Export `fadeOutDown` keyframes.
 */

export const fadeOutDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(32px);
  }
`;
