/* eslint-disable no-restricted-imports */

/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

/**
 * Export `ImageProps` type.
 */

export type ImageProps = Omit<ComponentPropsWithoutRef<'img'>, 'srcSet' | 'src'> & {
  blurred?: boolean;
  fill?: boolean;
  height?: number;
  priority?: boolean;
  src: string;
  width?: number;
};

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  --image-default-filter: blur(20px);

  display: block;
  position: relative;
  width: 100%;

  &[data-fill='true'] {
    height: 100%;
    inset: 0;
    position: absolute;
  }
`;

/**
 * `ImageElement` styled component.
 */

const ImageElement = styled.img`
  color: transparent;
  height: 100%;
  inset: 0;
  object-fit: cover;
  opacity: 1;
  position: absolute;
  transition: var(--transition-default);
  transition-property: filter, opacity;
  width: 100%;

  &[data-loading='true'] {
    filter: var(--image-default-filter);
    opacity: 0;
  }
`;

/**
 * Export `Image` component.
 */

export function Image(props: ImageProps) {
  const { alt, blurred = true, fill, height, priority, src, style, width, ...rest } = props;
  const [loading, setLoading] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);
  const onImageLoad = useCallback(() => {
    setLoading(false);
  }, []);

  const aspectRatio = useMemo(() => (height && width ? height / width : undefined), [height, width]);
  const imageProps = useMemo(
    () => ({
      ...rest,
      alt: alt ?? '',
      fetchpriority: priority ? 'high' : undefined,
      loading: (priority ? 'eager' : 'lazy') as 'eager' | 'lazy'
    }),
    [alt, priority, rest]
  );

  useEffect(() => {
    if (imageRef.current?.complete) {
      setLoading(false);
    }
  }, []);

  return (
    <ImageWrapper {...rest} data-fill={fill} style={{ aspectRatio, maxHeight: height, maxWidth: width, ...style }}>
      <ImageElement
        {...imageProps}
        data-loading={blurred && loading}
        onLoad={onImageLoad}
        ref={imageRef}
        src={src}
        style={style}
      />
    </ImageWrapper>
  );
}
