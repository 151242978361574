/**
 * Module dependencies.
 */

import axios from 'redaxios';

/**
 * Export `axiosNextInstance`.
 */

export const axiosNextInstance = axios.create({
  baseURL: '/api'
});
