/**
 * Constants.
 */

const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

/**
 * Export `googleRecaptcha`.
 */

export function googleRecaptcha() {
  const scriptExists = document.getElementById('googleRecaptchaScript');

  if (scriptExists || !recaptchaSiteKey) {
    return;
  }

  const googleRecaptchaScript = document.createElement('script');

  googleRecaptchaScript.id = 'googleRecaptchaScript';
  googleRecaptchaScript.async = true;
  googleRecaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaSiteKey}`;

  document.head.appendChild(googleRecaptchaScript);
}
